import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  TabStrip,
  TabStripTab,
  Card,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  ORGANIZATIONUSERS,
  ROLES,
} from "../../../../constants/applicationConstants";
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../../constants/applicationConstants";
import { hasUserRole } from "../../../../Utils/Auth/ValidatePermissions";
import { useAuth } from "../../../Core/Context/AuthContext";
const BaseOrganizationUserDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const OrganizationUserDataTable = DataTableHoc(BaseOrganizationUserDataTable);

/**
 * OrganizationUser main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationUser component.
 */
const OrganizationUser = () => {
  const [selected, setSelected] = React.useState(0);
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  const { user } = useAuth();
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-p-2">
        <React.Suspense fallback={<Loader />}>
          <Card className="detail-grid-section k-pt-3">
            <TabStrip
              className="page-tabs"
              selected={selected}
              onSelect={handleSelect}
            >
              <TabStripTab title="Users" contentClassName={`tabs-card`}>
                <Card className={`tabs-card detail-card`}>
                  <CardBody className="!k-px-0">
                    <div className="card-body">
                      <div className="container mx-auto k-p-2 add-page-section">
                        <OrganizationUserDataTable
                          deleteFilter={deleteFilter}
                          applyFilter={applyFilter}
                          saveFilters={saveFilters}
                          moduleName={ORGANIZATIONUSERS}
                          dataColumns={dataColumnsArray}
                          gridColumnsList={GridColumnsList}
                          dataTableName={ORGANIZATIONUSERS}
                          setIsReady={setIsReady}
                          isReady={isReady}
                          rowLayoutConfigName={`${ORGANIZATIONUSERS}.DataGrid.RowLayout`}
                          pageLengthConfigName={`${ORGANIZATIONUSERS}.DataGrid.ItemsPerPage`}
                          dataSet={userData}
                          setDataSet={setUserData}
                          initialSort={{
                            field: "id",
                            dir: "desc",
                          }}
                          isCardonMobileView={false}
                          total={process.env.DefaultLimit}
                          defaultRowLayout={process.env.RowLayout}
                          rowLayouts={ROW_LAYOUTS}
                          pageSizes={PAGE_SIZE}
                          isCompanyAdmin={hasUserRole(
                            user,
                            ROLES?.OrganizationAdmin
                          )}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </TabStripTab>
            </TabStrip>
          </Card>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationUser;
