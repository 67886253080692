import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import { APP_FLOW_DTL_ARG } from '../../../../constants/apiRoutes';

/**
 * list AppFlowDetailArgument.
 * @returns {Promise} - A promise that resolves with the list of AppFlowDetailArgument or rejects with an error.
 * @param props
 */
export const listAppFlowDetailArgument = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
  afId,
  dtlNum,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${APP_FLOW_DTL_ARG.replace('{afId}', afId).replace('{dtlNum}', dtlNum)}/Filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });

  // Add rowNumber for uniqueness
  const argumentsWithRowNumber = response?.Payload.map((item, index) => ({
    ...item,
    row_no: item.UcOssiAfStepArgId + index + 1, // Calculate rowNumber based on offset
  }));

  return {
    arguments: argumentsWithRowNumber,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create App Flow Argument.
 * @returns {Promise} - A promise that resolves with the creation of Test Argument or rejects with an error.
 * @param data
 */
export const createAppFlowDetailArgument = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  afId,
  dtlNum,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${APP_FLOW_DTL_ARG.replace('{afId}', afId).replace('{dtlNum}', dtlNum)}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update App Flow Argument.
 * @returns {Promise} - A promise that resolves with the update of Test Argument or rejects with an error.
 * @param data
 */
export const updateAppFlowDetailArgument = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  afId,
  dtlNum,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${APP_FLOW_DTL_ARG.replace('{afId}', afId).replace('{dtlNum}', dtlNum)}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Delete App Flow Argument
 * @returns {Promise} - A promise that resolves with the deletion of Test Argument or rejects with an error.
 * @param data
 */
export const deleteAppFlowDetailArgument = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  afId,
  dtlNum,
}) => {
  const { accessToken } = getTokensFromStorage();

  // Construct the query parameters to be added to the URL
  const queryParams = new URLSearchParams({
    prodId: data.prodId, // Include prodId as query param
    afStepArgId: data.afStepArgId, // Include afStepArgId as query param
    afDtlSeq: data.afDtlSeq,
  }).toString();
  // Construct the API URL with query parameters
  const url = `${APP_FLOW_DTL_ARG.replace('{afId}', data.afId).replace('{dtlNum}', data.dtlNum)}?${queryParams}`;

  const response = await apiRequest({
    url,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });

  return response;
};

/**
 * User Api Call Wrapper Method.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 * @param request
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
