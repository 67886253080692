import {
  replaceQuotes,
  serializeResponse,
  isLocalStorageAvailable,
  setItem,
} from 'smart-react';
import { GlobalConfigs } from './configKeysToSync';
import { sendNotification } from '../../../../Utils/ServiceWorker/serviceWorkerUtils';
import { EVENTS_DATA_TYPES } from '../../../../constants/eventDataTypes';
import { dexieCore } from '../../../../Utils/Storage/IndexDB/dexieDb';
import { APP_CONFIG } from '../../../../constants/applicationConstants';

/**
 * Set Global App Config in Index Db.
 * @param {*} response
 * @param {*} moduleName
 * @param {*} isServiceWorker
 */
export const setGlobalAppConfig = async (response, isServiceWorker) => {
  let entry = await serializeResponse(response, isServiceWorker);
  if (entry?.data !== null && !Array.isArray(entry?.errors)) {
    /**
     * get the filtered array that based on Global App config
     */
    let filtered = getGlobalAppConfigs(entry);
    if (filtered?.length > 0) {
      filtered = parseGlobalAppConfigs(filtered);
      await dexieCore.Configurations.bulkPut(filtered);
    }
    /**
     * Check if local storage available then update the local storage
     * otherwise send the notifications using SW.
     */
    if (isLocalStorageAvailable()) {
      filtered?.map((element) => {
        setItem(element.ConfigId, element);
      });
    } else {
      let notification = {
        moduleName: APP_CONFIG,
        subModules: filtered?.map((configKeys) => configKeys.ConfigId),
      };
      // Send Config keys Notification
      sendNotification(EVENTS_DATA_TYPES.SYNC_DB_LS, notification);
    }
  }
};

/**
 * Set App Config in Index Db.
 * @param {*} response
 * @param {*} isServiceWorker
 */
export const setAppConfig = async (response, isServiceWorker) => {
  let entry = await serializeResponse(response, isServiceWorker);
  if (entry?.data !== null && !Array.isArray(entry?.errors)) {
    let filteredGlobalConfig = getGlobalAppConfigs(entry);
    /**
     * Parse and set Global App Configs.
     */
    if (filteredGlobalConfig?.length > 0) {
      filteredGlobalConfig = parseGlobalAppConfigs(filteredGlobalConfig);
      await dexieCore.Configurations.bulkPut(filteredGlobalConfig);
    }

    /**
     * Check if local storage available then update the local storage
     * otherwise send the notifications using SW.
     */
    if (isLocalStorageAvailable()) {
      filteredGlobalConfig?.map((element) => {
        setItem(element.ConfigId, element);
      });
    } else {
      // Send Config keys Notification
      const globalKeys =
        filteredGlobalConfig?.map((configKeys) => configKeys.ConfigId) ?? [];
      const filteredKeys = [...globalKeys];
      let notification = {
        moduleName: APP_CONFIG,
        subModules: filteredKeys,
      };
      // Send Config keys Notification
      sendNotification(EVENTS_DATA_TYPES.SYNC_DB_LS, notification);
    }
  }
};
/**
 * Get Global App Configs
 * @param {*} entry
 * @returns {string} the global App Configs
 */
const getGlobalAppConfigs = (entry) => {
  let configkeys = GlobalConfigs;
  const gobalAppConfig = entry?.data?.listUserInfo?.AppConfig?.map(
    (element) => {
      if (configkeys.includes(element.ConfigType)) {
        return {
          ConfigId: element.ConfigType,
          value: element.ConfigData,
        };
      }
      return null;
    },
  );
  return gobalAppConfig?.filter((el) => {
    if (el !== undefined && el !== null && el.ConfigId !== undefined) {
      return true;
    }
    return false;
  }); // return non null objects
};

/**
 * Parse Global App config data and convert it into Json
 * @param {Array} globalConfig - The global app config to parse
 * @returns {Array} - The parsed global app config
 */
const parseGlobalAppConfigs = (globalConfig) =>
  globalConfig?.map((element) => {
    let { value } = element;
    if (value) {
      value = replaceQuotes(value);
      value = JSON.parse(value);
    }
    return {
      ConfigId: element.ConfigId,
      value,
    };
  });
