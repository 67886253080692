import * as React from "react";
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import RunSetArguments from "../../../RunSetArguments/Pages/RunSetArguments";
import RunSetDetails from "../../../RunSetDetails/Pages/RunSetDetails";
import { isComponentUpdate } from "smart-react";
/**
 * Test Details Desktop view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DesktopView component.
 */
const DesktopView = ({ detailsDataModified, state }) => {
  const [selected, setSelected] = React.useState(0);
  /**
   * Handle Selected Tab
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  return (
    <>
      <Card>
        <TabStrip
          className="page-tabs"
          selected={selected}
          onSelect={handleSelect}
        >
          <TabStripTab
            title="Detail"
            contentClassName={`tabs-card ${
              detailsDataModified === true ? "tab-modified" : ""
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? "tab-modified" : ""
              }`}
            >
              <CardBody>
                <div className="card-body">
                  <RunSetDetails runSet={state.dataSet} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
          <TabStripTab
            title="Argument"
            contentClassName={`tabs-card ${
              detailsDataModified === true ? "tab-modified" : ""
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? "tab-modified" : ""
              }`}
            >
              <CardBody>
                <div className="card-body">
                  <RunSetArguments runSet={state.dataSet} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
        </TabStrip>
      </Card>
    </>
  );
};

// Export the memoized component
export default React.memo(DesktopView, isComponentUpdate);
