import React from 'react';
import { Loader } from 'smart-react';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { ListView } from '@progress/kendo-react-listview';
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { buildNotification, GenerateNotification } from 'smart-react';
import { ListViewItemRender } from './ListView/ListView';
import LabelTag from '../Components/LabelTag/LabelTag';
import {
  isAllProductFeaturesSubscribed,
  isAllProductFeaturesExpired,
  isAnyProductFeatureExpired,
  isFeatureExpired,
  hasUserRole,
  isWorldTenant,
} from '../../../Utils/Auth/ValidatePermissions';
import { useAuth } from '../../../Modules/Core/Context/AuthContext';
import {
  listProducts,
  subscribeProducts,
  subscribeAllFeatures,
} from '../../Products/Services//ProductService';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import {
  SUBSCRIPTION_ERROR_MESSAGE,
  SUBSCRIPTION_SUCCESS_MESSAGE,
} from '../../../constants/notificationMessages';
import { ROLES } from '../../../constants/applicationConstants';
import './ProductList.scss';

const ProductList = ({
  tenantID,
  isOrganizationProduct,
  subscribed = true,
}) => {
  const [products, setProducts] = React.useState([]);
  const [loader, setIsLoader] = React.useState(true);
  const [expanded, setExpanded] = React.useState('');
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [aggrement, setAgreement] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [selectedFeature, setSelectedFeature] = React.useState(null);
  const [productID, setProductID] = React.useState(null);
  const [isRefesh, setIsRefesh] = React.useState(false);
  const { user, setFeatureData } = useAuth();
  const tooltip = React.useRef(null);
  const toggleDialog = () => {
    setProductID(null);
    setSelectedFeature(null);
    setVisibleDialog(!visibleDialog);
  };

  const handleSubscribeFeature = (featureId) => {
    toggleDialog();
    setSelectedFeature(featureId);
  };

  const handleSubscribeAll = (productId) => {
    toggleDialog();
    setProductID(productId);
  };

  const handleAgreementChange = (event) => {
    setAgreement(event.value);
  };

  /**
   * Handle Subscribe Form Submission
   */
  const submitDialog = async () => {
    if (aggrement) {
      setIsError(false);
      setIsLoader(true);
      if (productID === null) {
        const susbcribeProducts = await subscribeProducts({
          feature_id: selectedFeature,
          user,
          tenantId: tenantID,
        });
        if (susbcribeProducts?.IsSuccess) {
          GenerateNotification(
            buildNotification(SUBSCRIPTION_SUCCESS_MESSAGE),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
          setIsRefesh(!isRefesh);
        } else {
          GenerateNotification(
            buildNotification(SUBSCRIPTION_ERROR_MESSAGE),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
        }
      }
      if (selectedFeature === null) {
        const susbcribeProducts = await subscribeAllFeatures({
          product_id: productID,
          user,
          tenantId: tenantID,
        });
        if (susbcribeProducts?.IsSuccess) {
          GenerateNotification(
            buildNotification(SUBSCRIPTION_SUCCESS_MESSAGE),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
          setIsRefesh(!isRefesh);
        } else {
          GenerateNotification(
            buildNotification(SUBSCRIPTION_ERROR_MESSAGE),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
        }
      }
      setProductID(null);
      setSelectedFeature(null);
      toggleDialog();
      setIsLoader(false);
      setAgreement(false);
    } else {
      setIsError(true);
    }
  };
  const isFeaturesEnabled = (product) => {
    return product?.features.some((feature) => feature?.IsEnabled);
  };

  /**
   * Used to get list of products
   */
  React.useEffect(() => {
    (async () => {
      setIsLoader(true);
      const productsData = await listProducts({
        tenantId: tenantID,
        type: isOrganizationProduct ? 'organization' : 'both',
      });
      setProducts(productsData);
      setIsLoader(false);
    })();
  }, [isRefesh, tenantID]);

  /**
   * Used to get list of products
   */
  React.useEffect(() => {
    if (!isOrganizationProduct && products?.length > 0) {
      setFeatureData(products);
    }
  }, [products]);

  /**
   * Checks if the product features array has at least one feature with valid permissions
   * @param {Array} features - Array of product features
   * @returns {boolean} - True if at least one feature has permissions, false otherwise
   */
  const hasFeaturePermissions = (features) => {
    // Check if features is an array and not empty
    if (!Array.isArray(features) || features.length === 0) {
      return false;
    }

    // Check if any feature has a valid Permissions object
    return features.some((feature) => {
      // Check if Permissions object exists
      if (
        !feature.Permissions &&
        !isFeatureExpired(feature?.Permissions?.SubscriptionEndDate)
      ) {
        return false;
      }
      return true;
    });
  };

  const hasAnyFeaturePermissions = (products) => {
    return products.some((product) =>
      product.features.some(
        (feature) =>
          feature.Permissions !== null &&
          !isFeatureExpired(feature?.Permissions?.SubscriptionEndDate)
      )
    );
  };

  return (
    <React.Suspense fallback={<Loader />}>
      {loader && <Loader />}
      <div className='product-row k-pr-5 k-pl-5 k-pb-5 product-page'>
        {products?.length > 0
          ? products.map(
              (product, index) =>
                ((subscribed && hasFeaturePermissions(product?.features)) ||
                  !subscribed) && (
                  <ExpansionPanel
                    key={product.product_id}
                    title={
                      <span>
                        {product.display_name}{' '}
                        {isAllProductFeaturesExpired(product?.features) && (
                          <LabelTag className='error' text={'Expired'} />
                        )}
                        {isAllProductFeaturesSubscribed(product) &&
                          !isAnyProductFeatureExpired(product?.features) && (
                            <LabelTag className='success' text={'Subscribed'} />
                          )}
                      </span>
                    }
                    subtitle={
                      <button
                        className='subscribe-button'
                        onClick={() => handleSubscribeAll(product.product_id)}
                        disabled={
                          !isFeaturesEnabled(product) ||
                          (isOrganizationProduct &&
                            !hasUserRole(user, ROLES?.OrganizationAdmin)) ||
                          isAllProductFeaturesSubscribed(product) ||
                          isWorldTenant(tenantID) ||
                          subscribed
                        }
                      >
                        Subscribe All
                      </button>
                    }
                    expanded={expanded === product.product_id}
                    tabIndex={0}
                    onAction={(event) => {
                      setExpanded(event.expanded ? '' : product.product_id);
                    }}
                  >
                    <Reveal>
                      {expanded === product.product_id && (
                        <ExpansionPanelContent>
                          <div className='product-col'>
                            <Card className='product-subscription-card'>
                              <CardBody className='product-subscription-body'>
                                <ul className='subscription-features-list'>
                                  <ListView
                                    data={product?.features}
                                    item={(props) => (
                                      <ListViewItemRender
                                        {...props}
                                        subscribeFeature={
                                          handleSubscribeFeature
                                        }
                                        isOrganizationProduct={
                                          isOrganizationProduct
                                        }
                                        isCompanyAdmin={hasUserRole(
                                          user,
                                          ROLES?.OrganizationAdmin
                                        )}
                                        subscribed={subscribed}
                                        tenantID={tenantID}
                                      />
                                    )}
                                  />
                                </ul>
                              </CardBody>
                            </Card>
                          </div>
                        </ExpansionPanelContent>
                      )}
                    </Reveal>
                  </ExpansionPanel>
                )
            )
          : !loader && (
              <h6 className='k-py-5 k-text-center'>No Products Found</h6>
            )}

        {subscribed && !hasAnyFeaturePermissions(products) && (
          <h6 className='k-d-block k-mx-auto'>No Subscribed Products Found</h6>
        )}

        <Tooltip
          ref={tooltip}
          anchorElement='target'
          position='left'
          openDelay={300}
        />

        {visibleDialog && (
          <Dialog
            title='Please Confirm'
            onClose={toggleDialog}
            width={500}
            height={300}
          >
            <div style={{ margin: '25px', textAlign: 'center' }}>
              <p>
                The trial is available for BlueYonder employees and BlueYonder
                customers. It is not available for subcontractors that are
                working for BlueYonder or BlueYonder customers.
              </p>
              <Checkbox
                className={isError ? 'checkbox-error' : ''}
                validityStyles={true}
                label='I acknowledge that I meet the requirements of the trial'
                onChange={handleAgreementChange}
              />
            </div>
            <DialogActionsBar>
              <Button onClick={submitDialog} themeColor='primary'>
                Submit
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </React.Suspense>
  );
};

export default ProductList;
