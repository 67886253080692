import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import { AUTEST_TEST_CASE } from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
/**
 * list Tests.
 * @returns {Promise} - A promise that resolves with the list of Tests or rejects with an error.
 * @param props
 */
export const listTestCase = async ({
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };
  const { accessToken } = getTokensFromStorage();

  const response = await apiRequest({
    url: `${AUTEST_TEST_CASE.replace('{testId}', "''")}/Filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    tests: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Test.
 * @returns {Promise} - A promise that resolves with the creation of test or rejects with an error.
 * @param data
 */
export const createTestCase = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_TEST_CASE.replace('{testId}', data?.uc_ossi_app_test_id)}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Test.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const updateTestCase = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_TEST_CASE.replace('{testId}', data?.uc_ossi_app_test_id)}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Test.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const deleteTestCase = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_TEST_CASE.replace('{testId}', data?.uc_ossi_app_test_id)}/${data?.uc_ossi_test_case_id}?tenant_id=${data?.tenant_id}&uc_ossi_prod_id=${data?.uc_ossi_prod_id}&uc_ossi_app_test_id=${data?.uc_ossi_app_test_id}&uc_ossi_test_case_id=${data?.uc_ossi_test_case_id}&uc_env_id=${data?.uc_env_id}`,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * UserDetail Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
