// All App Notification Messages
// Delete message
export const DELETE_MESSAGE = {
  title: "Successfully Deleted",
  description: "Record has been deleted successfully.",
  style: "success",
};
// Create Message
export const CREATE_MESSAGE = {
  title: "Successfully Saved",
  description: "Your changes have been saved successfully.",
  style: "success",
};
// Update Message
export const UPDATE_MESSAGE = {
  title: "Successfully Saved",
  description: "Your changes have been saved successfully.",
  style: "success",
};
// Error Message
export const ERROR_MESSAGE = {
  title: "Something went wrong",
  description: "An error has been occurred.",
  style: "error",
};
// Approve UserDetail message
export const APPROVE_USER_MESSAGE = {
  title: "Successfully Approved",
  description: "UserDetail has been added in the organization successfully.",
  style: "success",
};
// Application Key message
export const APP_KEY_SUCEESS_MESSAGE = {
  title: "App Key Successfully Generated",
  description:
    "Copy the one-time generated App Key and keep it in a secure location.",
  style: "success",
};

// Update Password Message
export const UPDATE_PASSWORD_MESSAGE = {
  title: "Password Updated Successfully",
  description: "Your password has been successfully updated.",
  style: "success",
};

// Update Password Message
export const PASSWORD_NOT_MATCH = {
  title: "Passwords does not match!",
  description: "The passwords that you entered do not match.",
  style: "success",
};

export const PASSWORD_MESSAGES = {
  REQUIRED: "Please enter a value.",
  MIN_LENGTH: "Password must be at least 8 characters long.",
  UPPERCASE: "Password must contain at least one uppercase letter.",
  DIGIT: "Password must contain at least one digit.",
  SPECIAL_CHAR: "Password must contain at least one special character.",
  DEFAULT: "Password does not meet the required criteria.",
};
// SUBSCRIPTION ERROR MESSAGE
export const SUBSCRIPTION_ERROR_MESSAGE = {
  title: "Subscription Error",
  description:
    "There was an issue with your subscription. Please try again later.",
  style: "error",
};
// SUBSCRIPTION SUCCESS MESSAGE
export const SUBSCRIPTION_SUCCESS_MESSAGE = {
  title: "Succefully Subscribed",
  description: "You have access to fully functional feature for 90 days",
  style: "success",
};
