import { buildNotification, GenerateNotification } from 'smart-react';
import * as fetchIntercept from 'fetch-intercept';
import {
  APP_CONFIG_MODIFICATION,
  SYNC_PROCESS_INTERVAL,
  DisplayNotification,
} from '../../constants/applicationConstants';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../constants/eventDataTypes';
import { storageReset } from '../Storage/storageUtils';

// #region Service Worker functions
/**
 * Send Notification messages to all the clients.
 * @param {*} notificationType
 * @param {*} notification
 */
export const sendNotification = (
  notificationType,
  notification,
  isSingleClient = false,
) => {
  self.clients
    .matchAll({
      includeUncontrolled: true,
      type: 'window',
    })
    .then((clients) => {
      for (let i = 0; i < clients.length; i += 1) {
        if (notification.isDelay) {
          // on page refresh, client id refreshed as well
          // so get all the clients again and send the delay
          // notification on the very first client.
          let clientTosend = clients[i];
          setTimeout(() => {
            self.clients.get(clientTosend?.id).then((clientId) => {
              if (clientId) {
                clientTosend.postMessage({
                  type: EVENTS_DATA_TYPES.DELAY_NOTIFICATION,
                  notification,
                });
              } else {
                self.clients
                  .matchAll({
                    includeUncontrolled: true,
                    type: 'window',
                  })
                  .then((client) => {
                    client[0].postMessage({
                      type: EVENTS_DATA_TYPES.DELAY_NOTIFICATION,
                      notification,
                    });
                  });
              }
            });
          }, notification.delayTime);
        } else
          clients[i].postMessage({
            type: notificationType,
            notification,
          });
        if (isSingleClient) break;
      }
    });
};

/**
 * Send Notification of update service worker in case of Update
 */
export const updateServiceWorker = () => {
  self.clients
    .matchAll({
      includeUncontrolled: true,
      type: 'window',
    })
    .then((clients) => {
      for (let i = 0; i < clients.length; i += 1) {
        clients[i].postMessage({
          type: EVENTS_DATA_TYPES.UPDATE_SERVICE_WORKER,
        });
      }
    });
};

/**
 * Skip waiting and notification based on configuration
 * @returns {null} it performs async operations and returns null
 */
export const swSkipWaiting = () => {
  let swNotification = {
    title: 'New Version of the App Available!',
    description: `Please click <a>here</a> to get the latest version of the app`,
    style: 'info',
    timeout: 0,
    display: DisplayNotification.ALERT,
    callBack: () => {
      reloadAllClients();
    },
  };
  swNotification.callBack = swNotification.callBack.toString();
  sendNotification(
    EVENTS_DATA_TYPES.PROCESS_NOTIFICATION,
    buildNotification(swNotification),
  );
  self.skipWaiting();
  // If is auto update setting is true in the configuration,
  // post the message to service worker for skip waiting.
  caches
    .keys()
    .then((cacheNames) =>
      Promise.all(cacheNames.map((cache) => caches.delete(cache))),
    );

  configureResetEnvironment();
};

/**
 * Check the configuration for rest Env
 * unregister the SW, send notification to client refresh all clients
 */
export const configureResetEnvironment = () => {
  // Check for reset Configuration.
  let resetEnv = process.env.RESET_ENV;
  if (resetEnv.toLowerCase() === 'true') {
    self.registration
      .unregister()
      .then(() => {
        storageReset(true);
        let resetNotify = {
          title: 'Environment Reset',
          description: `Environment was reset. All local data has been cleared.`,
          style: 'info',
          timeout: 0,
          isDelay: true,
          delayTime: 5000,
          isSingleClient: true,
          callBack: () => {},
        };
        resetNotify.callBack = resetNotify.callBack.toString();
        let notification = buildNotification(resetNotify);
        GenerateNotification(
          notification,
          NOTIFICATION_TYPES.SERVICE_WORKER,
          EVENTS_DATA_TYPES.DELAY_NOTIFICATION,
        );
        return self.clients.matchAll();
      })
      .then((clients) => {
        clients.forEach((client) => {
          client.navigate(client.url);
        });
      });
  }
};

/**
 * Check if request is Rest call or not
 * @param {*} url
 * @param {*} request
 * @returns {boolean} returns if url is rest api or not
 */
export const isRestApi = (url) => {
  let isQuery = false;
  if (url.includes('/api/')) {
    isQuery = true;
  }
  return isQuery;
};

/**
 * Check the type of graphQL.
 * @param {*} url
 * @param {*} qlRequest
 * @returns {boolean} returns if url is graph QL or not
 */
export const isGraphQLQuery = async (url, request) => {
  let isQuery = false;
  if (url.includes('/graphql')) {
    let graphQLrespose = await request.json();
    if (graphQLrespose && graphQLrespose.query) {
      if (!graphQLrespose.query.toLowerCase().includes('mutation'))
        isQuery = true;
      else isQuery = false;
    }
  }
  return isQuery;
};

// #endregion

/**
 * Get the length of all clients
 * @returns {number} return length of clients
 */
const clientsCount = async () => {
  let clients = await self.clients.matchAll({
    includeUncontrolled: true,
    type: 'window',
  });
  return clients.length;
};

// #region Service worker registration
/**
 * Sync Process activities
 * @returns {Array[]} returns List of activities for sync process and sync interval
 */
export const syncProcessActivities = () =>
  // List of activies for sync process and sync interval.
  [
    // #region Application Configs
    [
      EVENTS_DATA_TYPES.APP_CONFIGS,
      APP_CONFIG_MODIFICATION,
      SYNC_PROCESS_INTERVAL.APP_CONFIG_MODIFICATION ??
        process.env.SYNC_INTERVAL,
    ],
    // #endregion  Application Configs
  ];

// #endregion

// #region
/**
 * Serialize is convoluted as headers is not a simple object.
 * @param {*} request
 * @returns
 */
export const serialize = async (request) => {
  var headers = {};
  // `for(... of ...)` is ES6 notation but current browsers supporting SW, support this
  // notation as well and this is the only way of retrieving all the headers.
  for (var entry of request.headers.entries()) {
    if (
      entry[0] !== 'limit' ||
      entry[0] !== 'modulename' ||
      entry[0] !== 'offset'
    )
      headers[entry[0]] = entry[1];
  }
  var serialized = {
    url: request.url,
    headers,
    method: request.method,
    mode: request.mode,
    credentials: request.credentials,
    cache: request.cache,
    redirect: request.redirect,
    referrer: request.referrer,
  };

  // Only if method is not `GET` or `HEAD` is the request allowed to have body.
  if (request.method !== 'GET') {
    // && request.method !== 'HEAD') {
    return request
      .clone()
      .text()
      .then((body) => {
        serialized.body = body;
        return Promise.resolve(serialized);
      });
  }
  return Promise.resolve(serialized);
};

/**
 * Compared, deserialize is pretty simple.
 * @param {*} request
 * @returns
 */
export const deserialize = async (request) => {
  let data = await serialize(request);
  return Promise.resolve(new Request(data.url, data));
};

/**
 * Clear all the running intervals
 * @param {*} syncActivities
 */
export const clearSyncProcess = (syncActivities) => {
  if (syncActivities.length > 0) {
    let syncProcessLength = syncActivities.length;
    while (syncProcessLength >= 0) {
      clearInterval(syncActivities[syncProcessLength]);
      syncActivities.splice(syncProcessLength, 1);
      syncProcessLength -= 1;
    }
  }
};

/**
 * Start sync process on page refresh and reload.
 */
export const processSyncOnRefresh = () => {
  const pageAccessedByReload =
    window.performance.getEntriesByType('navigation')[0].type;
  if (
    pageAccessedByReload === 'reload' ||
    pageAccessedByReload === 'navigate'
  ) {
    if (navigator.serviceWorker.controller) {
      let activities = syncProcessActivities();
      navigator.serviceWorker.controller.postMessage({
        type: EVENTS_DATA_TYPES.PERIODIC_SYNC_ACTIVITIES,
        activities,
      });
    }
  }
};

/**
 * used to intercept graphql api calls
 * attach token with graph ql api calls
 * if token expire update the token
 */
export const apiIntercepter = async () =>
  fetchIntercept.register({
    request: (url, config) => [url, config],
    requestError: (error) => Promise.reject(error),

    responseError: (error) => Promise.reject(error),
  });

// #endregion
