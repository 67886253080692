import React from "react";
import { convertToLocalDate } from "smart-react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({ editContentSlider }) => {
  const dataColumns = [
    {
      field: "uc_ossi_test_step_seq",
      title: "Test Step Sequence",
      editable: false,
      show: true,
      width: "250px",
      width: "250px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editContentSlider(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_test_step_seq}
        </td>
      ),
    },
    {
      field: "row_no",
      title: "Row No",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: "tenant_name",
      title: "Tenant",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: "uc_ossi_test_step_ord",
      title: "Test Step Order ",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_descr",
      title: "Description",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_af_id",
      title: "Application Flow Id",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_exec_cnt_arg",
      title: "Execution Count Argument",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_pre_cond_expr",
      title: "Pre Condition Expression ",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_val_expr",
      title: "Validation Expression ",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_step_run_till_expr",
      title: "Run While Condition Is True",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_post_expr",
      title: "Post Expression",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ins_user_id",
      title: "Created By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ins_dt",
      title: "Created Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.ins_dt)}</td>,
    },
    {
      field: "last_upd_user_id",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "last_upd_dt",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.last_upd_dt)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
