import Dexie from 'dexie';
import { CORE_STORE } from '../../../constants/applicationConstants';
import { EVENTS_DATA_TYPES } from '../../../constants/eventDataTypes';

export const StoreArray = {
  Core: {
    Store: CORE_STORE,
    Version: 9,
    Tables: {
      Configurations: 'ConfigId,value',
    },
  },
};

export const dexieCore = new Dexie(StoreArray.Core.Store);
dexieCore
  .version(parseInt(StoreArray.Core.Version))
  .stores(StoreArray.Core.Tables);
dexieCore.open().catch((err) => {
  dexieDbOpneHandler(err);
});

let isReset = true;

/**
 * Handle Dexie DB Error
 */
const dexieDbOpneHandler = (error) => {
  switch (error.name) {
    case Dexie.errnames.Version:
    case Dexie.errnames.DatabaseClosed:
      if (isReset) {
        dexieDbVerionError();
        isReset = false;
      }
      break;
    default:
      break;
  }
};
/**
 * Open the dexie db instance and check if Dexie version
 * error then reset the environment.
 */

export const dexieDbVerionError = () => {
  let notification = {};
  self.clients
    .matchAll({
      includeUncontrolled: true,
      type: 'window',
    })
    .then((clients) => {
      for (let i = 0; i < clients.length; i += 1) {
        clients[i].postMessage({
          type: EVENTS_DATA_TYPES.RESET_ENVIRONMENT,
          notification,
        });
      }
    });
};
