import { getCacheResponse } from 'smart-react';
import {
  APP_CONFIG,
  APP_CONFIG_GLOBAL,
} from '../../../constants/applicationConstants';
import {
  setAppConfig,
  setGlobalAppConfig,
} from '../../../Modules/Core/AppConfigs/Services/appConfigService';

// #region  GraphQl Cache

/**
 * Sets the cached response for a GraphQL request.
 * @param {Request} request - The request object.
 * @param {Response} response - The response object.
 * @param {boolean} [isServiceWorker=false] - Flag indicating if the function is being called from a service worker.
 */
export const setGraphqlCacheResponse = async (
  request,
  response,
  isServiceWorker = false,
) => {
  let module;
  if (!isServiceWorker) module = request.headers.get('moduleName');
  else module = request.moduleName;
  switch (module) {
    case `${APP_CONFIG}`:
      setAppConfig(response, isServiceWorker);
      break;
    case `${APP_CONFIG_GLOBAL}`:
      setGlobalAppConfig(response, isServiceWorker);
      break;
    default:
      break;
  }
};

/**
 * Returns a cached response for a GraphQL request.
 * @async
 * @function getGraphqlCacheResponse
 * @param {Request} request - The request object.
 * @param {boolean} [isServiceWorker=false] - Flag indicating if the function is being called from a service worker.
 * @returns {Promise<Object>} - A promise that resolves to an object representing the cached response, or an error message if no cached response is found.
 */
export const getGraphqlCacheResponse = async (
  request,
  isServiceWorker = false,
) => {
  let data;
  let module;
  try {
    if (!isServiceWorker) module = request.headers.get('moduleName');
    else module = request.moduleName;
    switch (module) {
      default:
        break;
    }
    let cacheResponse = await getCacheResponse(data);
    return cacheResponse;
  } catch (err) {
    return { isError: true, message: err.message };
  }
};

/**
 * Updates the GraphQL cache response for a specific module.
 * @param {Request} request - The request object.
 * @param {Response} response - The response object.
 * @returns {Promise<void>} - A Promise that resolves when the cache response has been updated.
 */
const updateGraphqlCacheResponse = async (request, response) => {
  let moduleName = request.headers.get('moduleName');
  switch (moduleName) {
    default:
      break;
  }
};
// #endregion

// #region  Index Db cache
/**
 * Sets the cache response in IndexDB based on the request URL.
 * @param {Request} request - The request object.
 * @param {Response} response - The response object.
 * @returns {Object|undefined} - An object with isError and message properties if the request URL does not match a valid route, otherwise undefined.
 */
export const setIndexDbCacheResponse = async (request, response) => {
  if (request.url.includes('/graphql'))
    return setGraphqlCacheResponse(request, response);
  return { isError: true, message: 'No route found' };
};

/**
 * Get Index DB cache response.
 * Returns a cached response from IndexedDB.
 * @param {Request} request - The request object.
 * @returns {Promise<Object>} - A promise that resolves to an object representing the cached response, or an error message if no cached response is found.
 */
export const getIndexDbCacheResponse = async (request) => {
  if (request.url.includes('/graphql'))
    return getGraphqlCacheResponse(request, false);
  return { isError: true, message: 'No route found' };
};

/**
 * Updates the cached response in IndexedDB.
 *
 * @param {Request} request - The request object.
 * @param {Response} response - The response object.
 * @returns {Promise<Object>} - A promise that resolves to an object representing the updated cached response, or an error message if no cached response is found.
 */
export const updateIndexDbCacheResponse = async (request, response) => {
  if (request.url.includes('/graphql'))
    return updateGraphqlCacheResponse(request, response);
  return { isError: true, message: 'No route found' };
};
// #endregion
