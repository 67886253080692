import { DataColumns } from "../DataColumns/DataColumns";
import GridFlag from "../../../../Core/Components/GridFlag/GridFlag";

/**
 * Generates a list of columns for a grid.
 * @param {object} options - The options for the grid.
 * @returns {Array} - The list of grid columns.
 */
export const GridColumnsList = async ({ editScreen }) => {
  return DataColumns({ editScreen, GridFlag });
};

export default GridColumnsList;