import * as React from 'react';
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';
import AppFlowSteps from '../../../AppFlowSteps/Pages/AppFlowSteps';

/**
 * Test Details Desktop view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DesktopView component.
 */
const DesktopView = ({
  selected,
  handleSelect,
  detailsDataModified,
  state,
}) => {
  return (
    <>
      <Card>
        <TabStrip
          className="page-tabs"
          selected={selected}
          onSelect={handleSelect}
        >
          <TabStripTab
            title="Steps"
            contentClassName={`tabs-card ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className="card-body ">
                  <AppFlowSteps flow={state.dataSet} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
        </TabStrip>
      </Card>
    </>
  );
};
export default DesktopView;
