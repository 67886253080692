import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { LOADCOMMANDS } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
import { useNavigate } from 'react-router-dom';
import './Commands.scss';
const BaseCommandDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
const CommandDataTable = DataTableHoc(BaseCommandDataTable);

/**
 * LoadCommands main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The LoadCommands component.
 */
const LoadCommands = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  const navigate = useNavigate();
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  /**
   * navigate to edit screen
   * @param {*} event
   */
  const editScreen = (dataItem) => {
    navigate(`/autest/command`, {
      state: {
        edit: true,
        dataSet: dataItem,
      },
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <CommandDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={LOADCOMMANDS}
            dataColumns={dataColumnsArray}
            gridColumnsList={() => GridColumnsList({ editScreen })}
            dataTableName={LOADCOMMANDS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${LOADCOMMANDS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${LOADCOMMANDS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default LoadCommands;
