import {
  setItem,
  getItem,
  fetchItem,
  removeItem,
  updateItem,
} from 'smart-react';
import { dexieCore } from './IndexDB/dexieDb';

/**
 * Get cache configuration from local storage or indexedDB.
 * @async
 * @param {string} key - The key to retrieve the configuration value.
 * @param {boolean} isServiceWorker - Indicates whether the code is running in a service worker context.
 * @returns {Promise<object|null>} The cache configuration object or null if not found.
 */
export const getCacheConfig = async (key, isServiceWorker = false) => {
  if (isServiceWorker) {
    return (await getCoreConfiguration(key)) ?? null;
  }
  return getItem(key) ?? (await getCoreConfiguration(key)) ?? null;
};

/**
 * Synchronous version of getCacheConfig
 * Get cache configuration synchronously from local storage or indexedDB.
 * @param {string} key - The key to retrieve the configuration value.
 * @param {boolean} isServiceWorker - Indicates whether the code is running in a service worker context.
 * @returns {object|null} The cache configuration object or null if not found.
 */
export const getCacheConfigSync = (key, isServiceWorker = false) => {
  if (isServiceWorker) {
    let cacheResponse = getCoreConfiguration(key);
    return cacheResponse ?? null;
  }
  return getItem(key) ?? getCoreConfiguration(key) ?? null;
};

/**
 * Get data from storage, using a fallback value if the data is not found.
 * @async
 * @param {string} localValueKey - The key to retrieve the local value.
 * @param {string} globalValueKey - The key to retrieve the global value.
 * @param {any} defaultValue - The default value to return if the data is not found.
 * @returns {Promise<any>} The retrieved data, or the default value if the data is not found.
 */
export const getDataFromStorage = async (
  localValueKey,
  globalValueKey,
  defaultValue,
) => {
  let data =
    (await getCacheConfig(localValueKey)) ??
    (await getCacheConfig(globalValueKey, false));

  return data ?? defaultValue;
};

/**
 * Remove data from local storage and also core configurations db
 * @async
 * @param {string} key - The key to remove the data from.
 * @param {string} name - The name of the data to remove (optional).
 * @returns {Promise<void>}
 */
export const removeDataFromStorage = async (key, name = '') => {
  if (name) {
    updateItem(key, name);
  } else {
    removeItem(key);
  }
  await deleteCoreConfiguration(key, name);
};

/**
 * Get cache configuration from session storage or indexedDB.
 * @async
 * @param {string} key - The key to retrieve the configuration value.
 * @param {boolean} isServiceWorker - Indicates whether the code is running in a service worker context.
 * @returns {Promise<object|null>} The cache configuration object or null if not found.
 */
export const getSessionCacheConfigData = async (
  key,
  isServiceWorker = false,
) => {
  if (isServiceWorker) {
    return (await getCoreConfiguration(key)) ?? null;
  }
  return (
    sessionStorage.getItem(key) ?? (await getCoreConfiguration(key)) ?? null
  );
};

/**
 * Set cache configuration to session storage and indexedDB.
 * @param {string} key - The key to set the configuration value to.
 * @param {object} value - The value to set.
 * @returns {void}
 */
export const setSessionCacheConfigData = (key, value) => {
  sessionStorage.setItem(key, value);
  setCoreConfiguration(key, value);
};

/**
 * Set cache configuration to local storage and indexedDB.
 * @async
 * @param {string} key - The key to set the configuration value to.
 * @param {object} value - The value to set.
 * @returns {Promise<void>}
 */
export const setCacheConfig = async (key, value) => {
  setItem(key, value);
  await setCoreConfiguration(key, value);
};

/**
 * Get core configuration from indexedDB.
 * @async
 * @param {string} moduleName - The name of the module to retrieve configuration for.
 * @returns {Promise<object|null>} The core configuration object or null if not found.
 */
export const getCoreConfiguration = async (moduleName) => {
  let config = await dexieCore.Configurations.get(moduleName);
  return config?.value ?? null;
};

/**
 * Set core configuration to indexedDB.
 * @async
 * @param {string} moduleName - The name of the module to set configuration for.
 * @param {object} response - The configuration data to set
 */
export const setCoreConfiguration = async (moduleName, response) => {
  let config = {
    ConfigId: `${moduleName}`,
    value: response,
  };
  await dexieCore.Configurations.put(config);
};

/**
 * Delete core configuration from indexedDB.
 * @async
 * @param {string} moduleName - The name of the module to delete configuration for.
 * @param {*} subModuleName - The name of the sub module to delete configuration for.
 */
export const deleteCoreConfiguration = async (
  moduleName,
  subModuleName = '',
) => {
  if (subModuleName) {
    // remove sub data from dexie
    let data = await dexieCore.Configurations.get(moduleName);
    if (data) {
      data[subModuleName] = '';
      setCoreConfiguration(moduleName, data);
    }
  } else await dexieCore.Configurations.delete(moduleName);
};

/**
 * Fetch core configuration from indexedDB and local storage.
 * @async
 * @param {string} moduleName - The name of the module to retrieve configuration for.
 * @param {*} key - The name of the key to retrieve configuration for.
 * @returns {Promise<object|null>} The configuration object or null if not found.
 */
export const fetchCacheConfig = async (moduleName, key) =>
  fetchItem(moduleName, key) ?? (await fetchdexieCore(moduleName, key)) ?? null;

/**
 * Fetch core configuration from indexedDB.
 * @async
 * @param {string} moduleName - The name of the module to retrieve configuration for.
 * @param {*} key - The name of the key to retrieve configuration for.
 * @returns {Promise<object|null>} The core configuration object or null if not found.
 */
export const fetchdexieCore = async (moduleName, key) => {
  const data = (await dexieCore.Configurations.get(moduleName)) ?? null;
  if (data) {
    return data[key];
  }
  return data;
};
