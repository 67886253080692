import { apiCall } from "../../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../../Utils/Auth/AuthToken";
import { AUTEST_EXECUTIONS } from "../../../../constants/apiRoutes";
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from "../../../../Utils/Storage/IndexDB/indexDbWrappers";
/**
 * list Executions.
 * @returns {Promise} - A promise that resolves with the list of Executions or rejects with an error.
 * @param props
 */
export const listExecution = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${AUTEST_EXECUTIONS}/Filter`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    executions: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * get Execution Analytics.
 * @returns {Promise} - A promise that resolves with the list of Execution Analytics or rejects with an error.
 * @param props
 */
export const getExecutionAnalytics = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_EXECUTIONS}/Analytics`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    analyticsData: response.Payload,
  };
};

/**
 * Execution Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of Execution or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
