import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { TESTSTEPS } from "../../../../constants/applicationConstants";
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../../constants/applicationConstants";
const BaseTestStepDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const TestStepDataTable = DataTableHoc(BaseTestStepDataTable);

/**
 * TestSteps main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The TestSteps component.
 */
const TestSteps = ({ test }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);
  /**
   * show edit content slider
   * @param {*} dataItem
   */
  const editContentSlider = (dataItem) => {
    setEditItem(dataItem);
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4">
        <React.Suspense fallback={<Loader />}>
          <TestStepDataTable
            test={test}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={TESTSTEPS}
            dataColumns={dataColumnsArray}
            gridColumnsList={() => GridColumnsList({ editContentSlider })}
            dataTableName={TESTSTEPS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${TESTSTEPS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${TESTSTEPS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            editItem={editItem}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default TestSteps;
