import { environment } from 'smart-react';
// Events Triggered from Application
export const EVENTS_DATA_TYPES = {
  SKIP_WAITING: 'SKIP_WAITING',
  UPDATE_SERVICE_WORKER: 'UPDATE_SERVICE_WORKER',
  PERIODIC_SYNC_ACTIVITIES: 'PERIODIC_SYNC_ACTIVITIES',
  PROCESS_NOTIFICATION: 'PROCESS_NOTIFICATION',
  APPLICATION_NOTIFICATION: 'APPLICATION_NOTIFICATION',
  NETWORK_STATUS: 'NETWORK_STATUS',
  APP_CONFIGS: 'APP_CONFIGS',
  SYNC_DB_LS: 'SYNC_DB_LS', // Sync DB with Local Storage
  SYNC_DB_SS: 'SYNC_DB_SS', // Sync DB with Session Storage
  RESET_ENVIRONMENT: 'RESET_ENVIRONMENT', // Reset environment
  DELAY_NOTIFICATION: 'DELAY_NOTIFICATION', // Delay Notification
  RELOAD: 'RELOAD',
};

// Device Types to test responsiveness
export const DEVICE_TYPES = {
  PHONE: 'Phone',
  TABLET: 'Tablet',
  LAPTOP: 'Laptop',
  DESKTOP: 'Desktop',
};

/**
 * Notification Types
 */
export const NOTIFICATION_TYPES = {
  SERVICE_WORKER: 'SERVICE_WORKER',
  APP: 'APP',
};
