import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { FEATURE_PERMISSION } from "../../../constants/apiRoutes";
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from "../../../Utils/Storage/IndexDB/indexDbWrappers";
/**
 * Lists products.
 * @returns {Promise} - A promise that resolves with the list of products or rejects with an error.
 * @param props
 */
export const listProducts = async ({ isServiceWorker, tenantId, type }) => {
  const { accessToken } = getTokensFromStorage();
  const routeName = `${FEATURE_PERMISSION}?EntityId=${tenantId}&Type=${type}`;
  const response = await productsApiCall({
    url: `${routeName}`,
    method: "GET",
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return response.Payload;
};

/**
 * susbcribe feature.
 * @returns {Promise} - A promise that resolves with the list of users or rejects with an error.
 * @param props
 */
export const subscribeProducts = async ({ feature_id, tenantId }) => {
  const { accessToken } = getTokensFromStorage();
  const data = [
    {
      TenantId: tenantId,
      FeatureId: feature_id,
      active: true,
    },
  ];
  const response = await productsApiCall({
    url: `${FEATURE_PERMISSION}`,
    body: data,
    method: "POST",
    accessToken,
  });
  return response;
};
/**
 * susbcribe All feature.
 * @returns {Promise} - A promise that resolves with the list of users or rejects with an error.
 * @param props
 */
export const subscribeAllFeatures = async ({ product_id, tenantId }) => {
  const { accessToken } = getTokensFromStorage();
  const routeName = `${FEATURE_PERMISSION}/${tenantId}/Products`;
  const data = [
    {
      product_id: product_id,
      tenant_id: tenantId,
      active: true,
    },
  ];
  const response = await productsApiCall({
    url: `${routeName}`,
    body: data,
    method: "POST",
    accessToken,
  });
  return response;
};

/**
 * UserDetail Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const productsApiCall = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
