import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ListView } from "@progress/kendo-react-listview";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../constants/eventDataTypes";
import { ROLES } from "../../../constants/applicationConstants";
import {
  getOrganizationDetail,
  updateOrganization,
} from "./Services/OrganizationService";
import { listProducts } from "../../Products/Services/ProductService";
import { useAuth } from "../../Core/Context/AuthContext";
import {
  Loader,
  ErrorFallback,
  TextInput,
  TextAreaInput,
  InputSwitch,
  isValidate,
  isFromValid,
} from "smart-react";
import { Form, Field } from "@progress/kendo-react-form";
import {
  Card,
  CardBody,
  CardHeader,
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  hasUserRole,
  getDefaultTenant,
} from "../../../Utils/Auth/ValidatePermissions";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ListViewItemRender } from "../OrganizationProducts/Components/ListView/ListView";
import ApplicationKeys from "../../Core/ApplicationKey/Pages/ApplicationKeys";
import { buildNotification, GenerateNotification } from "smart-react";
import {
  ERROR_MESSAGE,
  CREATE_MESSAGE,
} from "../../../constants/notificationMessages";
import "./Organization.scss";

const Organization = () => {
  const { getTokensFromStorage, user, tenantID } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [formData, setFormData] = React.useState();
  const [expanded, setExpanded] = React.useState("Brazil");
  const [orgId, setOrgId] = React.useState("");
  const [validateForm, setValidateForm] = React.useState(false);
  const tooltip = React.useRef(null);
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const requiredFields = ["obj_prefix", "name", "email_domain", "description"];
  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    (async () => {
      setLoading(true);
      const response = await getOrganizationDetail({
        accessToken,
        isServiceWorker: false,
        user,
      });
      setLoading(false);
      let responseData = response?.data?.filter((org) => org.name !== "world");
      if (responseData?.length > 0) {
        let tempData = responseData?.filter(
          (org) => org.tenant_id === tenantID
        );
        if (tempData?.length > 0) {
          setFormData(tempData[0]);
          setOrgId(tempData[0].organization_id);
        } else {
          let tempData = responseData?.filter(
            (org) => org.tenant_id === getDefaultTenant()?.TenantId
          );
          setFormData(tempData[0]);
          setOrgId(tempData[0].organization_id);
        }
      }
    })();
  }, [tenantID]);

  React.useEffect(() => {
    setDetailsDataModified(hasUserRole(user, "companyadmin"));
  }, [user]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async () => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);

    let response;

    let data = {
      organization_id: formData?.organization_id,
      organizationVM: formData,
    };

    try {
      setValidateForm(false);
      response = await updateOrganization({
        data,
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification(CREATE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async () => {
    setLoading(true);
    const productsData = await listProducts({
      orgId,
      isSubscribed: false,
      tenantId: tenantID,
      type: "organization",
    });
    setProducts(productsData);
    setLoading(false);
  };

  /**
   * Checks if the product features array has at least one feature with valid permissions
   * @param {Array} features - Array of product features
   * @returns {boolean} - True if at least one feature has permissions, false otherwise
   */
  const hasFeaturePermissions = (features) => {
    // Check if features is an array and not empty
    if (!Array.isArray(features) || features.length === 0) {
      return false;
    }

    // Check if any feature has a valid Permissions object
    return features.some((feature) => {
      // Check if Permissions object exists
      if (!feature.Permissions) {
        return false;
      }
      return true;
    });
  };

  const hasAnyFeaturePermissions = (products) => {
    return products.some((product) =>
      product.features.some((feature) => feature.Permissions !== null)
    );
  };

  React.useEffect(() => {
    getProducts();
  }, [tenantID]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        {loading && <Loader />}
        <div className="add-test main-page-section add-page-section">
          <Card className="k-mt-3 detail-card">
            <CardHeader>
              <h6 className="card-title-secondary">Details</h6>
            </CardHeader>
            <CardBody className="detail-card-body">
              <div>
                <Form
                  onSubmit={onSave}
                  render={(formRenderProps) => (
                    <div className="card-content">
                      <div className="k-d-flex single-field-row">
                        <div
                          className={`field-wrapper  ${isValidate(validateForm, formData?.obj_prefix) ? "field-invalid" : "field-valid"}`}
                        >
                          <Field
                            key={"obj_prefix"}
                            id={"obj_prefix"}
                            name={"obj_prefix"}
                            data={formData?.obj_prefix}
                            onChange={handleInputChange}
                            label={"Prefix"}
                            type={"text"}
                            component={TextInput}
                            optional={false}
                            renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                            requiredField={true}
                          />
                          {isValidate(validateForm, formData?.obj_prefix) && (
                            <Error>This field cannot be empty</Error>
                          )}
                        </div>
                        <div
                          className={`field-wrapper ${isValidate(validateForm, formData?.name) ? "field-invalid" : "field-valid"}`}
                        >
                          <Field
                            key={"name"}
                            id={"name"}
                            name={"name"}
                            data={formData?.name}
                            onChange={handleInputChange}
                            label={"Name"}
                            type={"text"}
                            component={TextInput}
                            optional={false}
                            renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                            requiredField={true}
                          />
                          {isValidate(validateForm, formData?.name) && (
                            <Error>This field cannot be empty</Error>
                          )}
                        </div>
                      </div>
                      <div className="k-d-flex single-field-row">
                        <div
                          className={`field-wrapper field-wrapper-100 ${isValidate(validateForm, formData?.description) ? "field-invalid" : "field-valid"}`}
                        >
                          <Field
                            key={"description"}
                            id={"description"}
                            name={"description"}
                            data={formData?.description}
                            onChange={handleInputChange}
                            label={"Description"}
                            type={"text"}
                            row={2}
                            component={TextAreaInput}
                            style={{
                              width: "100%",
                            }}
                            renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                            optional={false}
                            requiredField={true}
                          />
                          {isValidate(validateForm, formData?.description) && (
                            <Error>This field cannot be empty</Error>
                          )}
                        </div>
                      </div>
                      <div className="k-d-flex single-field-row">
                        <div className={`field-wrapper`}>
                          <Field
                            key={"email_domain"}
                            id={"email_domain"}
                            name={"email_domain"}
                            data={formData?.email_domain}
                            onChange={handleInputChange}
                            label={"Email Domains"}
                            type={"text"}
                            component={TextInput}
                            optional={false}
                            renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                          />
                        </div>
                        <div className={`field-wrapper k-mt-auto`}>
                          <Field
                            name="active"
                            component={InputSwitch}
                            label="Active"
                            onLabel="Yes"
                            offLabel="No"
                            id="active"
                            checked={formData?.active}
                            onChange={handleInputChange}
                            disabled={!detailsDataModified}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </CardBody>
          </Card>
          {detailsDataModified && (
            <Card className="k-mt-3">
              <CardHeader>
                <div className="k-d-flex single-field-row button-form-wrapper">
                  <>
                    <Button themeColor={"primary"} onClick={onSave}>
                      Save
                    </Button>
                  </>
                </div>
              </CardHeader>
            </Card>
          )}
          <Card className="detail-card k-mt-3">
            <CardHeader>
              <h6 className="card-title-secondary">Subscribed Products</h6>
            </CardHeader>
            <CardBody className="!k-px-0 !k-py-0 detail-card">
              <div className="product-row k-p-5 product-page">
                {hasAnyFeaturePermissions(products) &&
                  products?.map(
                    (product, index) =>
                      hasFeaturePermissions(product?.features) && (
                        <ExpansionPanel
                          title={product.display_name}
                          expanded={expanded === product.product_id}
                          tabIndex={0}
                          key={product.product_id}
                          onAction={(event) => {
                            setExpanded(
                              event.expanded ? "" : product.product_id
                            );
                          }}
                        >
                          <Reveal>
                            {expanded === product.product_id && (
                              <ExpansionPanelContent>
                                <div className="product-col">
                                  <Card
                                    className="product-subscription-card "
                                    key={index}
                                  >
                                    <CardBody className="product-subscription-body">
                                      <ul className="subscription-features-list">
                                        <ListView
                                          data={product?.features}
                                          item={(props) => (
                                            <ListViewItemRender
                                              {...props}
                                              isCompanyAdmin={hasUserRole(
                                                user,
                                                ROLES?.OrganizationAdmin
                                              )}
                                              subscribed={true}
                                              tenantID={tenantID}
                                            />
                                          )}
                                        />
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </div>
                              </ExpansionPanelContent>
                            )}
                          </Reveal>
                        </ExpansionPanel>
                      )
                  )}
                {!hasAnyFeaturePermissions(products) && (
                  <h6 className="k-d-block k-mx-auto">
                    No Subscribed Products Found
                  </h6>
                )}
                <Tooltip
                  ref={tooltip}
                  anchorElement="target"
                  position="left"
                  openDelay={300}
                />
              </div>
            </CardBody>
          </Card>
          {detailsDataModified && (
            <Card className="k-mt-3 application-key-card">
              <CardHeader>
                <h6 className="card-title-secondary">Application Key</h6>
              </CardHeader>
              <CardBody>
                <div className="card-body add-page-section no-grid-scroll-horizontal">
                  <ApplicationKeys isOrganization={true} orgId={orgId} />
                </div>
              </CardBody>
            </Card>
          )}
        </div>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Organization;
