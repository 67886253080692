import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback } from 'smart-react';
import { useAuth } from '../../../Core/Context/AuthContext';
import ProductList from '../../../Core/ProductList/ProductList';
/**
 * OrganizationProducts main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationProducts component.
 */
const OrganizationProducts = () => {
  const { tenantID } = useAuth();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <ProductList
            isOrganizationProduct={true}
            tenantID={tenantID}
            subscribed={false}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationProducts;
