import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXP,
} from '../../constants/applicationConstants';
/**
 * Returns an object with id, access, and refresh tokens retrieved from session storage.
 * @function getTokensFromStorage
 * @returns {Object} An object containing idToken, accessToken, and refreshToken.
 */
export const getTokensFromStorage = () => ({
  accessToken: sessionStorage.getItem(ACCESS_TOKEN),
  idToken: sessionStorage.getItem(ACCESS_TOKEN),
  refreshToken: sessionStorage.getItem(REFRESH_TOKEN),
  tokenExp: sessionStorage.getItem(TOKEN_EXP),
});
export default getTokensFromStorage;
