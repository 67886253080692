import React from "react";
import { convertToLocalDate } from "smart-react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({ editScreen, GridFlag }) => {
  const dataColumns = [
    {
      field: "SRFProfileId",
      title: "Profile Id",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "SRFProfileGroupId",
      title: "Profile Group Id",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "SRFProfilesName",
      title: "Profile Name",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.SRFProfilesName}
        </td>
      ),
    },
    {
      field: "TenantId",
      title: "Tenant Id",
      editable: false,
      show: false,
      width: "250px",
      minResizableWidth: 150,
    },
    {
      field: "SRFProfilesDetails",
      title: "Description",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "AppURL",
      title: "App Url",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "ProfileDspSequence",
      title: "Profile Sequence",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "Active",
      title: "Active",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      cell: GridFlag,
    },
    {
      field: "created_by",
      title: "Created By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "created_date",
      title: "Created Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.created_date)}</td>
      ),
    },
    {
      field: "modified_by",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "modified_date",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.modified_date)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
