import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { TESTS } from "../../../../../constants/applicationConstants";
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from "smart-react";
import { GridColumnsList } from "../Filter/FilterElements";
import DataColumns from "../DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../../../constants/applicationConstants";
const BaseTestDataTable = React.lazy(
  () => import("./DataTable")
);
const TestsDataTable = DataTableHoc(BaseTestDataTable);

/**
 * Topbar of Equipment Lookup page
 * Includes Favourite and Recent button with Equipment Lookup search Box
 */
const TestDataTableWrapper = ({
  selectedTreeObj,
  selectedTree,
  topbarSearch,
  setTopBarSearch,
  isSearch,
  setIsSearch,
  setIsFilterFavourite,
  isFilterFavourite,
  setLookupCheckedItems,
  lookupCheckedItems,
  mode,
  lookupSelectionMode,
  setIsRecent,
  isRecent,
  selectLookups,
  show,
  handleEquipmentSlide,
}) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <div className="container mx-auto">
          <React.Suspense fallback={<Loader />}>
            <TestsDataTable
              deleteFilter={deleteFilter}
              applyFilter={applyFilter}
              saveFilters={saveFilters}
              moduleName={TESTS}
              dataColumns={dataColumnsArray}
              gridColumnsList={GridColumnsList}
              dataTableName={TESTS}
              setIsReady={setIsReady}
              isReady={isReady}
              rowLayoutConfigName={`${TESTS}.DataGrid.RowLayout`}
              pageLengthConfigName={`${TESTS}.DataGrid.ItemsPerPage`}
              dataSet={userData}
              setDataSet={setUserData}
              initialSort={{
                field: "id",
                dir: "desc",
              }}
              isCardonMobileView={false}
              total={process.env.DefaultLimit}
              defaultRowLayout={process.env.RowLayout}
              rowLayouts={ROW_LAYOUTS}
              pageSizes={PAGE_SIZE}
              selectedTreeObj={selectedTreeObj}
              selectedTree={selectedTree}
              topbarSearch={topbarSearch}
              setTopBarSearch={setTopBarSearch}
              setIsSearch={setIsSearch}
              isSearch={isSearch}
              isRecent={isRecent}
              setIsRecent={setIsRecent}
              setIsFilterFavourite={setIsFilterFavourite}
              isFilterFavourite={isFilterFavourite}
              setLookupCheckedItems={setLookupCheckedItems}
              lookupCheckedItems={lookupCheckedItems}
              mode={mode}
              lookupSelectionMode={lookupSelectionMode}
              contentSliderTitle={"Eq Lookups"}
              selectLookups={selectLookups}
              show={show}
              handleEquipmentSlide={handleEquipmentSlide}
            />
          </React.Suspense>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default TestDataTableWrapper;
