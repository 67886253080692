import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSliders, faUnlink } from '@fortawesome/free-solid-svg-icons';

const DataColumns = ({
  onAddChild,
  deleteFlowStepDialog,
  fetchArgumentsForStep,
}) => [
  {
    title: 'Actions',
    width: '20px',
    cell: (props) => (
      <td>
        <div className="k-d-flex">
          {props.dataItem.StepType === 'APPFLOW' && (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className="k-mr-2"
                onClick={() => onAddChild(props.dataItem)}
                style={{ cursor: 'pointer', color: '#4caf50' }}
              />
            </>
          )}
          <FontAwesomeIcon
            icon={faUnlink}
            className="k-mr-2"
            onClick={() => deleteFlowStepDialog(props.dataItem)}
            style={{ cursor: 'pointer', color: '#f44336' }}
          />
        </div>
      </td>
    ),
  },
  {
    field: 'Arguments',
    title: 'Arguments',
    width: '10px',
    cell: (props) => {
      const isTopLevelParent = !props?.dataItem?.parent;
      return (
        <td>
          {isTopLevelParent && (
            <div className="k-d-block k-text-center">
              <FontAwesomeIcon
                icon={faSliders}
                className="k-mr-2"
                onClick={() => fetchArgumentsForStep(props?.dataItem)}
                style={{ cursor: 'pointer', color: '#f44336' }}
              />
            </div>
          )}
        </td>
      );
    },
  },
  { field: 'UcOssiAfStepId', title: 'Step ID', expandable: true },
  { field: 'UcOssiAfId', title: 'Flow ID' },
  {
    field: 'StepType',
    title: 'Step Type',
    cell: (props) => {
      const hasChildren = props.dataItem.children?.length > 0;
      const isAppFlow = props.dataItem.StepType === 'APPFLOW';
      return (
        <td>
          <div className="k-d-flex">
            {isAppFlow && (
              <span className={`badge k-d-flex k-align-items-center`}>
                <span className={`circle ${hasChildren ? 'approved' : ''}`} />
              </span>
            )}
            {props.dataItem.StepType}
          </div>
        </td>
      );
    },
  },
];

export default DataColumns;
