import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import {
  ORGANIZATION,
  ORGANIZATION_PRODUCTS,
  ORGANIZATION_PENDING_APPROVAL,
} from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
/**
 * getOrganizationList.
 * @returns {Promise} - A promise that resolves OrganizationDetail Detail or rejects with an error.
 */
export const getOrganizationList = async ({
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ORGANIZATION}`,
    method: 'GET',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return response.Payload;
};
/**
 * getOrganizationDetail.
 * @returns {Promise} - A promise that resolves OrganizationDetail Detail or rejects with an error.
 */
export const getOrganizationDetail = async ({
  accessToken,
  moduleName,
  isServiceWorker,
  listController,
  user,
}) => {
  const response = await apiRequest({
    url: `${ORGANIZATION}/user?UserId=${user?.userId}`,
    method: 'GET',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return {
    data: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * getOrganizationSubscribedProduct.
 * @returns {Promise} - A promise that resolves OrganizationDetail Detail or rejects with an error.
 */
export const getOrganizationSubscribedProduct = async ({
  moduleName,
  isServiceWorker,
  listController,
  orgId,
  isSubscribed,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ORGANIZATION_PRODUCTS.replace('{orgId}', orgId)}?OrgId=${orgId}&isSubscribed=${isSubscribed}`,
    method: 'GET',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response.Payload;
};

/**
 * Update OrganizationDetail
 * @returns {Promise} - A promise that resolves with the update of Organizations or rejects with an error.
 * @param props
 */
export const updateOrganization = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ORGANIZATION}`,
    body: data,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * request OrganizationDetail
 * @returns {Promise} - A promise that resolves with the update of Organizations or rejects with an error.
 * @param props
 */
export const requestOrganization = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ORGANIZATION}/${data[0]?.organization_id}/Users`,
    body: data,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * getPendingApproval.
 * @returns {Promise} - A promise that resolves OrganizationDetail Detail or rejects with an error.
 */
export const getPendingApproval = async ({
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: ORGANIZATION_PENDING_APPROVAL,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response.Payload;
};

/**
 * UserDetail Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
