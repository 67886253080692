import React from "react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = () => {
  const dataColumns = [
    {
      field: "user_id",
      title: "UserDetail Id",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "Username",
      title: "UserDetail Name",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "EmailAddress",
      title: "Email Address",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "FirstName",
      title: "First Name",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "LastName",
      title: "Last Name",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
  ];

  return dataColumns;
};
export default DataColumns;
