import React from 'react';
import { convertToLocalDate } from 'smart-react';

/**
 * Data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = (editScreen) => {
  const dataColumns = [
    {
      field: 'row_no',
      title: 'Row no',
      editable: false,
      show: false,
      width: '250px',
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: 'uc_ossi_af_id',
      title: 'Application Flow',
      editable: false,
      show: true,
      width: '600px',
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_af_id}
        </td>
      ),
    },
    {
      field: 'tenant_name',
      title: 'Tenant',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 150,
    },
    {
      field: 'uc_ossi_descr',
      title: 'Description',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_cmd',
      title: 'Command',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_grp',
      title: 'Group',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'ins_user_id',
      title: 'Created By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'ins_dt',
      title: 'Created Date',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.ins_dt)}</td>,
    },
    {
      field: 'last_upd_user_id',
      title: 'Modified By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'last_upd_dt',
      title: 'Modified Date',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.last_upd_dt)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
