import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownInput, SliderCard, Loader } from 'smart-react';
import AppFlowContentSilder from '../../AppFlow/Components/ContentSlider/AppFlowContentSilder';
import {
  LookUp_Mode,
  Lookup_Selection_Mode,
} from '../../../../constants/applicationConstants';
import AppFlowLookupSlider from '../Components/ContentSliders/AppFlowLookupSlider';
import { isFromValid, isValidate } from 'smart-react';
import { Error } from '@progress/kendo-react-labels';
/**
 * AddAppFlowStep component
 * @param {Object} props - Component props
 * @param {boolean} props.show - Flag to show or hide the slider
 * @param {Object} [props.selectedParent] - The selected parent application flow step
 * @param {Function} props.setShowSlider - Callback to toggle slider visibility
 * @param {Array} props.stepTypes - List of step types for dropdown
 * @param {Array} props.applicationFlows - List of available application flows
 * @param {Array} props.steps - List of predefined steps for selection
 * @param {Array} props.specialCodes - List of special codes for selection
 * @param {Function} props.onSave - Callback to save the application flow step
 * @param {boolean} props.isSubmitted - Submission state of the form
 * @param {boolean} props.loading - Loading state for the component
 */
const AddAppFlowStep = ({
  show,
  selectedParent,
  selectedOption,
  setSelectedOption,
  setShowSlider,
  stepTypes,
  applicationFlows,
  steps,
  specialCodes,
  onSave,
  isSubmitted,
  loading,
}) => {
  const requiredFields = ['selectedUcOssiAfStepId'];
  const [validateForm, setValidateForm] = React.useState(false);
  const [selectedAppFlow, setSelectedAppFlow] = useState(null);
  const [selectedAppFlowStep, setSelectedAppFlowStep] = useState(null);
  const [selectedSpecialCode, setSelectedSpecialCode] = useState(null);
  const [appFlowSlideShow, setAppFlowSlideShow] = React.useState(false);
  const [selectedAFLookupItem, setSelectedAFLookupItem] = React.useState(null);

  let selectedUcOssiAfStepId = null;

  // Function to reset fields dynamically based on the selected option
  const resetFields = () => {
    switch (selectedOption?.value) {
      case 'ApplicationFlow':
        setSelectedAppFlow(null);
        break;
      case 'PredefinedStep':
        setSelectedAppFlowStep(null);
        break;
      case 'SpecialCode':
        setSelectedSpecialCode(null);
        break;
      default:
        setSelectedAppFlow(null);
        setSelectedAppFlowStep(null);
        setSelectedSpecialCode(null);
        break;
    }
    setValidateForm(false);
  };

  /**
   * Save the selected flow step
   */
  const handleSave = () => {
    setValidateForm(true);

    // Determine the value to send based on the selected option type
    if (selectedOption.Value === 'APPFLOW' && selectedAppFlow) {
      selectedUcOssiAfStepId = selectedAppFlow.uc_ossi_af_id;
    } else if (selectedOption.Value === 'DEFAFSTP' && selectedAppFlowStep) {
      selectedUcOssiAfStepId = selectedAppFlowStep.UcOssiAfStepId;
    } else if (selectedOption.Value === 'PREDEFLST' && selectedSpecialCode) {
      selectedUcOssiAfStepId = selectedSpecialCode.Value; // Adjust based on your data structure
    }

    const formData = {
      selectedUcOssiAfStepId: selectedUcOssiAfStepId ?? null,
    };

    // Validate form data using isFromValid
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }

    // Prepare data for saving
    const addFlowStepData = {
      selectedOption: selectedOption.value,
      selectedUcOssiAfStepId,
    };

    // Trigger the save operation
    onSave(addFlowStepData);
    resetFields();
  };

  // Handle reset when selectedAFLookupItem changes
  React.useEffect(() => {
    if (selectedAFLookupItem) {
      setSelectedAppFlow(selectedAFLookupItem[0]);
    }
  }, [selectedAFLookupItem]);

  // Handle option change
  React.useEffect(() => {
    resetFields();
  }, [selectedOption]);

  // Handle parent change
  React.useEffect(() => {
    setSelectedOption(null);
    resetFields();
  }, [selectedParent]);

  // Initialize with default option
  React.useEffect(() => {
    setSelectedOption(stepTypes[0]);
  }, []);

  /**
   * Used to show/hide test case lookup slide
   */
  const handleAppFlowSlide = () => {
    if (appFlowSlideShow) {
      setAppFlowSlideShow(false);
    } else {
      setAppFlowSlideShow(true);
    }
  };

  return (
    <>
      <AppFlowContentSilder show={show}>
        <SliderCard
          handleSlide={() => setShowSlider(false)}
          title="Add Application Flow Step"
          className="test-doc-slider"
        >
          <SliderCard.Body>
            {loading && <Loader />}
            {selectedParent && (
              <div className="!k-mt-5 !k-mb-1">
                <strong>Parent:</strong> <p>{selectedParent?.UcOssiAfStepId}</p>
              </div>
            )}
            <div>
              <DropDownInput
                label="Select Type"
                textField="DisplayName"
                dataItemKey="Value"
                className="!k-mb-3"
                dataSet={stepTypes}
                value={selectedOption}
                defaultItem={{
                  DisplayName: 'Select Step Type...',
                  Value: '',
                }}
                defaultValue={{
                  DisplayName: 'Select Step Type...',
                  Value: '',
                }}
                handleChange={(e) => {
                  setSelectedOption(e.value);
                }}
                filterable={true}
                requiredField={true}
              />
            </div>
            {selectedOption?.Value === 'APPFLOW' && (
              <div className="!k-mt-5">
                <label>Select Application Flow:</label>
                <div className="k-align-items-center k-d-flex k-align-items-center k-align-content-center">
                  <DropDownInput
                    textField="uc_ossi_af_id"
                    dataItemKey="uc_ossi_af_id"
                    className="testcase-dropdown k-align-self-end !k-mt-1"
                    defaultItem={{
                      uc_ossi_af_id: 'Select Application Flow...',
                    }}
                    defaultValue=""
                    dataSet={applicationFlows}
                    value={selectedAppFlow}
                    handleChange={(e) => {
                      setSelectedAppFlow(e.value);
                    }}
                  />
                  <Button
                    themeColor={'primary'}
                    className="k-mx-1 !k-mt-1"
                    icon="search"
                    onClick={() => setAppFlowSlideShow(true)}
                  ></Button>
                </div>
              </div>
            )}

            {selectedOption?.Value === 'DEFAFSTP' && (
              <DropDownInput
                label="Select User Step"
                textField="UcOssiAfStepId"
                dataItemKey="UcOssiAfStepId"
                dataSet={steps}
                defaultValue=""
                defaultItem={{
                  UcOssiAfStepId: 'Select Application Flow Step...',
                }}
                value={selectedAppFlowStep}
                handleChange={(e) => {
                  setSelectedAppFlowStep(e.value);
                }}
              />
            )}
            {selectedOption?.Value === 'PREDEFLST' && (
              <DropDownInput
                label="Select Special Code"
                textField="Value"
                dataItemKey="DisplayName"
                defaultValue=""
                dataSet={specialCodes}
                filterable={true}
                defaultItem={{
                  Value: 'Select Code...',
                }}
                value={selectedSpecialCode}
                handleChange={(e) => {
                  setSelectedSpecialCode(e.value);
                }}
              />
            )}
            {isValidate(validateForm, selectedUcOssiAfStepId) && (
              <Error>This field cannot be empty</Error>
            )}
          </SliderCard.Body>
          <SliderCard.Footer>
            {isSubmitted === false ? (
              <>
                <Button
                  icon="cancel"
                  onClick={() => setShowSlider(false)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button themeColor="primary" icon="save" onClick={handleSave}>
                  Save
                </Button>
              </>
            ) : (
              <Button onClick={() => setShowSlider(false)} type="button">
                Close
              </Button>
            )}
          </SliderCard.Footer>
        </SliderCard>
      </AppFlowContentSilder>
      <AppFlowLookupSlider
        show={appFlowSlideShow}
        handleSlide={handleAppFlowSlide}
        setSelectedLookupItems={setSelectedAFLookupItem}
        selectedLookupItems={selectedAFLookupItem}
        lookupSelectionMode={Lookup_Selection_Mode.single}
        mode={LookUp_Mode.LookUp}
      />
    </>
  );
};

export default AddAppFlowStep;
