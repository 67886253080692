import React from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";
import { FormInput, MessageAlert } from "smart-react";
import { apiRequest } from "../../../../../Middleware/API/apiRequest";
import "../../Pages/Splash.scss";

const Register = ({ setIsLoader }) => {
  const [formState, setFormState] = React.useState({});
  const navigate = useNavigate();
  const [formKey, setFormKey] = React.useState(1);
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const emailValidator = (value) =>
    emailRegex.test(value) ? "" : "Please enter a valid email.";
  const inputValidator = (value) =>
    value?.length > 0 ? "" : "Please enter a value.";
  const [alertMessage, setAlertMessage] = React.useState({
    alert: false,
    message: null,
    type: "danger",
    iconName: "error",
  });

  /**
   * Validate form
   */
  const formValidator = () => {
    return true;
  };

  const handleSubmit = async (dataItem) => {
    sessionStorage.setItem("isRegisteredMessageShown", false);
    const isValidate = formValidator();

    if (isValidate) {
      setIsLoader(true);
      dataItem = {
        Appuservm: {
          UserRoles: [],
          UserProfile: {
            UserName: dataItem?.email,
            AccessCode: "00000000-0000-0000-0000-000000000000",
            FirstName: dataItem?.fname,
            MiddleName: "",
            LastName: dataItem?.lname,
            TimeZone: "",
            Email: dataItem?.email,
            FileData: "",
            AuthGatewayId: "OISDB",
          },
        },
      };
      const baseURL = `${process.env.BASE_URL}/api/Register/User`;
      const request = {
        baseURL,
        method: "POST",
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
          "X-Application-Id": process.env.APP_ID,
          "X-App-Key": process.env.APP_KEY,
          "X-App-Secret": process.env.APP_SECRET,
        },
        body: JSON.stringify(dataItem),
      };

      try {
        const response = await apiRequest(request);
        if (!response?.IsSuccess) {
          setAlertMessage({
            alert: true,
            type: "danger",
            message: response?.StatusDesc,
            iconName: "error",
          });
        } else {
          setFormKey(formKey + 1);

          navigate(`/login`, {
            state: {
              isRegistered: true,
            },
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoader(false);
      }
    }
  };
  return (
    <>
      {alertMessage?.alert && (
        <MessageAlert
          type={alertMessage?.type}
          message={alertMessage?.message}
          isIcon={true}
          iconName={alertMessage?.iconName}
        />
      )}

      <Form
        initialValues={formState}
        onSubmit={handleSubmit}
        key={formKey}
        render={(formRenderProps) => (
          <FormElement>
            <div>
              <Field
                key={"email"}
                id={"email"}
                name={"email"}
                label={"Email"}
                labelClass={"k-font-bold"}
                inputClass={"k-login-input"}
                type={"email"}
                component={FormInput}
                optional={false}
                validator={emailValidator}
              />
              <Field
                key={"fname"}
                id={"fname"}
                name={"fname"}
                label={"First Name"}
                labelClass={"k-font-bold"}
                inputClass={"k-login-input"}
                type={"text"}
                component={FormInput}
                optional={false}
                validator={inputValidator}
              />
              <Field
                key={"lname"}
                id={"lname"}
                name={"lname"}
                label={"Last Name"}
                labelClass={"k-font-bold"}
                inputClass={"k-login-input"}
                type={"text"}
                component={FormInput}
                optional={false}
                validator={inputValidator}
              />
            </div>
            <div
              style={{
                justifyContent: "space-between",
                alignContent: "center",
              }}
              className={
                "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
              }
            >
              <Button
                className="k-w-100"
                themeColor={"dark"}
                type={"submit"}
                rounded={"medium"}
                size={"medium"}
                disabled={!formRenderProps.allowSubmit}
              >
                Register
              </Button>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};
export default Register;
