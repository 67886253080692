import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { USER_TENANTS } from "../../../constants/applicationConstants";
import { useAuth } from "../Context/AuthContext";
import { filterBy } from "@progress/kendo-data-query";
import { Loader, ErrorFallback, UserAvatar } from "smart-react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import ApplicationKey from "./Components/ApplicationKey/ApplicationKey";
import "./Profile.scss";

const Profile = () => {
  const { tenantID, updateTenant, profileData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [tenantList, SetTenantLists] = React.useState(
    JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
      (userTenant) => userTenant.IsWrite === true
    )
  );
  const [tenant, setTenant] = React.useState(
    JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
      (userTenant) => userTenant.TenantId === tenantID
    )[0]
  );
  /**
   * filter Data
   * @filter object
   * @return Array
   */
  const filterData = (filter) => {
    const data = tenantList.slice();
    return filterBy(data, filter);
  };
  /**
   * filter Change
   * @event object
   */
  const filterChange = (event) => {
    SetTenantLists(filterData(event.filter));
  };

  /**
   * handleTenant
   * @poram event object
   * @return
   */
  const handleTenant = (event) => {
    let tenant_id = event.target.value.TenantId;
    let tenant_name = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
      (userTenant) =>
        userTenant.TenantId === tenant_id && userTenant.IsWrite === true
    )[0];
    setTenant(tenant_name);
    updateTenant(tenant_name?.TenantId);
  };

  const defaultItem = {
    TenantName: "Select Tenant ...",
    disabled: true,
  };

  /**
   * set tenant on tenant change from topbar
   */
  React.useEffect(() => {
    setTenant(
      JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
        (userTenant) => userTenant.TenantId === tenantID
      )[0]
    );
  }, [tenantID]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        <Card className="tile-layout-card">
          <CardTitle>
            <div className="page-title page-button-title">Profile</div>
          </CardTitle>
          <CardBody>
            <div
              id="Profile"
              className="profile-page main-content container mx-auto"
            >
              {loading && <Loader />}
              <div className="card-row">
                <div className="card-column user-av-wrapper">
                  <UserAvatar />
                </div>
                <div className="card-column fields-column k-align-items-center k-d-flex k-justify-content-center">
                  <div className="user-profile">
                    <div className="k-d-flex field-row">
                      <div>
                        <strong className="field-label">Name:</strong>{" "}
                        <span className="field-value">
                          {profileData?.Appuservm?.FirstName}{" "}
                          {profileData?.Appuservm?.LastName}
                        </span>
                      </div>
                      <div className="email-row">
                        <strong className="field-label">Email:</strong>{" "}
                        <span className="field-value">
                          {profileData?.Appuservm?.EmailAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="tile-layout-card">
          <CardTitle>
            <div className="page-title page-button-title">Tenant</div>
          </CardTitle>
          <CardBody>
            <div
              id="Profile"
              className="profile-page main-content container mx-auto"
            >
              <div className="k-pb-3 k-px-3 single-field-row">
                <div className="field-wrapper">
                  <div className="k-floating-label-container">
                    <label className="k-label">Tenant</label>
                    <DropDownList
                      data={tenantList}
                      textField="TenantName"
                      dataItemKey="TenantId"
                      className="tenant-dropdown"
                      filterable={true}
                      onFilterChange={filterChange}
                      onChange={handleTenant}
                      value={tenant}
                      defaultItem={defaultItem}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <ApplicationKey />
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Profile;
