import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import {
  APP_FLOW,
  APP_FLOW_DTL,
  APP_FLOW_STEPS,
} from '../../../../constants/apiRoutes';
import { LISTING_LIMIT } from '../../../../constants/applicationConstants';

/**
 * list Steps.
 * @returns {Promise} - A promise that resolves with the list of Tests or rejects with an error.
 * @param props
 */
export const listSteps = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${APP_FLOW_STEPS}/filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    steps: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * list Application Flow Steps By AF ID
 * @returns {Promise} - A promise that resolves with the list of Test Arguments or rejects with an error.
 * @param props
 */
export const listAppFlowStepByID = async ({
  limit,
  offset,
  filter,
  sort = [],
  uc_ossi_af_id,
  isStepRequired = true,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset === 0 ? 1 : (offset ?? 1),
    pageSize: limit ?? LISTING_LIMIT,
    sort,
  };
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${APP_FLOW_DTL.replace('{afId}', uc_ossi_af_id)}/Filter/${isStepRequired}`,
    method: 'POST',
    body: data,
    accessToken,
  });
  return {
    steps: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Application Flow Step.
 * @returns {Promise} - A promise that resolves with the creation of Application Flow or rejects with an error.
 * @param data
 */
export const createFlowStep = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${APP_FLOW_DTL.replace('{afId}', data.UcOssiAfId)}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Application Flow.
 * @returns {Promise} - A promise that resolves with the update of Application Flow or rejects with an error.
 * @param data
 */
export const updateFlowStep = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${APP_FLOW}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Delete Application Flow Step.
 * @returns {Promise} - A promise that resolves with the update of Application Flow or rejects with an error.
 * @param data
 */
export const deleteFlowStep = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${APP_FLOW_DTL}?prodId=${data?.UcOssiProdId}&afId=${data?.UcOssiAfId}&afDtlSeq=${data?.UcOssiAfDtlSeq}`,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
