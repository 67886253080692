import React from "react";
import { convertToLocalDate } from "smart-react";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({ editScreen, GridFlag }) => {
  const dataColumns = [
    {
      field: "uc_ossi_run_id",
      title: "Run Id",
      editable: false,
      show: true,
      width: "500px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_run_id}
        </td>
      ),
    },
    {
      field: "TenantName",
      title: "Tenant",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 150,
    },
    {
      field: "uc_ossi_grp",
      title: "Group",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_disable_flg",
      title: "Disabled?",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      cell: GridFlag,
    },
    {
      field: "uc_ossi_descr",
      title: "Description",
      editable: false,
      show: true,
      width: "300px",
      minResizableWidth: 50,
    },
    {
      field: "ins_user_id",
      title: "Created By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ins_dt",
      title: "Created Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.ins_dt)}</td>,
    },
    {
      field: "last_upd_user_id",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "last_upd_dt",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.last_upd_dt)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
