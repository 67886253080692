import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback } from "smart-react";
import { Card } from "@progress/kendo-react-layout";
import "./DeviceEnrollment.scss";
import { groupBy } from "@progress/kendo-data-query";
import { ListView } from "@progress/kendo-react-listview";
import DeviceEnrollmentContentSlider from "../Components/ContentSlider/DeviceEnrollmentContentSlider";
import { listRFProfiles } from "../Services/DeviceEnrollmentService";
import { Pager } from "@progress/kendo-react-data-tools";
import QRCodeSlider from "../../../Core/Components/QRCodeSlider/QRCodeSlider";
import {
  HeaderRender,
  ListViewItemRender,
} from "../Components/ListView/ListView";

/**
 * DeviceEnrollment main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DeviceEnrollment component.
 */
const DeviceEnrollment = () => {
  const [singleProfile, setSingleProfile] = useState("");
  const [showQRModal, setShowQRModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [pagerData, setPagerData] = React.useState({
    skip: 0,
    take: 50,
  });
  const [isReloadData, setIsReloadData] = React.useState(false);
  const [totalCount, setTotalCount] = useState(-1);
  /**
   * handle Generate QRCode
   * @param profile
   */
  const handleGenerateQRCode = (profile) => {
    setShowQRModal(true);
    setSingleProfile(profile);
  };

  /**
   * fetch Profiles Data.
   */
  const fetchProfileData = async () => {
    setLoading(true);
    try {
      const response = await listRFProfiles({
        limit: pagerData?.take,
        offset: pagerData?.skip,
        filter: [],
        sort: [],
      });
      const result = groupBy(response.profiles, [
        { field: "ProfileGroupName" },
      ]);
      setProfileData(result);
      setTotalCount(response?.TotalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e) => {
    setPagerData({
      skip: e.skip,
      take: e.take,
    });
  };

  useEffect(() => {
    fetchProfileData();
  }, [pagerData, isReloadData]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Handle error boundary reset
      }}
    >
      {loading && <Loader />}
      <div className="configuration-container">
        <Card className="configuration-card">
          <div className="k-px-3 k-pb-5">
            {profileData?.map((profile, index) => (
              <ListView
                data={profile?.items}
                item={(props) => (
                  <ListViewItemRender
                    {...props}
                    handleGenerateQRCode={handleGenerateQRCode}
                  />
                )}
                header={() => <HeaderRender title={profile?.value} />}
              />
            ))}
            {profileData?.length <= 0 && !loading && (
              <h6 className="k-pt-5 k-text-center">
                No Device Enrollments Found
              </h6>
            )}
            {parseInt(totalCount) > 50 && (
              <div>
                <Pager
                  className="k-listview-pager"
                  skip={pagerData?.skip}
                  take={pagerData?.take}
                  onPageChange={handlePageChange}
                  total={totalCount}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
      <DeviceEnrollmentContentSlider show={showQRModal}>
        <QRCodeSlider
          showQRModal={showQRModal}
          singleProfile={singleProfile}
          setSingleProfile={setSingleProfile}
          setShowQRModal={setShowQRModal}
          isReloadData={isReloadData}
          setIsReloadData={setIsReloadData}
        />
      </DeviceEnrollmentContentSlider>
    </ErrorBoundary>
  );
};

export default DeviceEnrollment;
