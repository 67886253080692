import { getItem, setItem } from 'smart-react';
import Dexie from 'dexie';
import { getCoreConfiguration } from './handleCacheConfig';
/**
 * Delete all the dexie Db collections.
 */
export const deleteDexieDB = () => {
  Dexie.getDatabaseNames((names, cb) => {
    names.forEach(async (name) => {
      let db = new Dexie(name);
      db.close();
      await db
        .delete()
        .then(() => {
          console.log('Database successfully deleted: ', name);
        })
        .catch((err) => {
          console.error('Could not delete database: ', name, err);
        })
        .finally(() => {
          if (typeof cb === 'function') {
            cb();
          }
        });
    });
  });
};

/**
 * Clear local storage by removing all the keys/values.
 */
export const clearLocalStorage = () => {
  localStorage.clear();
};

/**
 * Clear session storage by removing all the keys/values.
 */
export const clearSessionStorage = () => {
  sessionStorage.clear();
};

/**
 * Delete all the cache from indexdb/Local/session storage and recreate Index DB.
 */
export const storageReset = (isServiceWorker = false) => {
  if (isServiceWorker) {
    deleteDexieDB();
  } else {
    deleteDexieDB();
    clearLocalStorage();
    clearSessionStorage();
  }
};

/**
 * Sync Index Db data with localstorage
 * if indexdb data is different from local storage it will update local storage
 * @param {String} key
 */
export const syncStorage = async (key) => {
  await getCoreConfiguration(key).then((dbData) => {
    if (JSON.stringify(getItem(key)) !== JSON.stringify(dbData)) {
      setItem(key, dbData);
    }
  });
};
/**
 * Sync Index Db data with Session Storage
 * if index db data is different from session storage it will update session storage
 * @param {String} key
 */
export const syncSessionStorage = async (key) => {
  await getCoreConfiguration(key).then((dbData) => {
    if (
      JSON.stringify(sessionStorage.getItem(key)) !== JSON.stringify(dbData)
    ) {
      sessionStorage.setItem(key, dbData);
    }
  });
};
