import React from 'react';
import { ListViewHeader } from '@progress/kendo-react-listview';
import LabelTag from '../../Components/LabelTag/LabelTag';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  isFeatureSubscribed,
  isFeatureExpired,
  isWorldTenant,
} from '../../../../Utils/Auth/ValidatePermissions';
import { convertToLocalDate } from 'smart-react';

/**
 * Header Render
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Header component.
 */
export const HeaderRender = (props) => {
  return (
    <ListViewHeader className='listview-header'>{props.title}</ListViewHeader>
  );
};

/**
 * ListViewItemRender
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Item Render component.
 */
export const ListViewItemRender = ({
  dataItem,
  subscribeFeature,
  isCompanyAdmin,
  isOrganizationProduct,
  tenantID,
  subscribed,
}) => {
  let feature = dataItem;
  const tooltip = React.useRef(null);
  // Check if we should render the component
  const shouldRender =
    !subscribed || (subscribed && isFeatureSubscribed(feature));

  if (!shouldRender) {
    return null;
  }
  return (
    <div className='k-listview-item product-listview product-row subscription-feature-item'>
      <div className='subscription-feature-title'>
        <label>
          {feature?.display_name}{' '}
          {isFeatureExpired(feature?.Permissions?.SubscriptionEndDate) && (
            <LabelTag className='error' text={'Expired'} />
          )}
          {isFeatureSubscribed(feature) &&
            !isFeatureExpired(feature?.Permissions?.SubscriptionEndDate) && (
              <LabelTag className='success' text={'Subscribed'} />
            )}
        </label>
        {feature?.Permissions?.SubscriptionEndDate && (
          <span className='expiry-date'>
            Expiration Date:{' '}
            {convertToLocalDate(feature?.Permissions?.SubscriptionEndDate)}
          </span>
        )}
        <small className='text-error'>
          {!feature?.IsEnabled && !isFeatureSubscribed(feature)
            ? 'If you want to try out and you are not allowed to do so, send email to sales@smart-is.com'
            : ''}
        </small>
      </div>
      <div
        className='btn-subscribe-wrapper'
        onMouseOver={(event) => {
          return tooltip.current && tooltip.current.handleMouseOver(event);
        }}
        onMouseOut={(event) => {
          return tooltip.current && tooltip.current.handleMouseOut(event);
        }}
      >
        <button
          className={`subscribe-button`}
          onClick={() => subscribeFeature(feature?.feature_id)}
          disabled={
            !feature?.IsEnabled ||
            isFeatureSubscribed(feature) ||
            isFeatureExpired(feature?.Permissions?.SubscriptionEndDate) ||
            (isOrganizationProduct && !isCompanyAdmin) ||
            isWorldTenant(tenantID)
          }
          title={
            !feature?.IsEnabled && !isFeatureSubscribed(feature)
              ? 'If you want to try out and you are not allowed to do so, send email to sales@smart-is.com'
              : ''
          }
        >
          {'Subscribe'}
        </button>
      </div>
      <Tooltip
        ref={tooltip}
        anchorElement='target'
        position='left'
        openDelay={300}
      />
    </div>
  );
};
export default ListViewItemRender;
