import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

/**
 * Get the Route Object of active Route.
 * @returns {Object} Route Object
 */
const useMatchedRoute = (RoutesItems) => {
  const { pathname } = useLocation();
  for (const route of RoutesItems) {
    if (route?.route) {
      if (matchPath({ path: route.route }, pathname)) {
        return route;
      }
    }
  }
  return {};
};
export default useMatchedRoute;
