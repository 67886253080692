import * as React from 'react';
import './LabelTag.scss';

/**
 * LabelTag
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Custom Checkbox component.
 */
export const LabelTag = ({ text, className }) => {
  return <span className={`label-msg ${className}`}>{text}</span>;
};

export default LabelTag;
