import {
  USER_TENANTS,
  SMART_IS_EMAIL,
  AUTH_PERMISSION,
  USER_DATA,
  ROLES,
  DEFAULT_TENANT,
} from "../../constants/applicationConstants";

export const ValidatePermissions = (item) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  let filterTenants = userTenants.filter(
    (userTenant) =>
      (userTenant.TenantId.toLowerCase() === item?.tenant_id?.toLowerCase() ||
        userTenant.TenantId.toLowerCase() === item?.TenantId?.toLowerCase() ||
        userTenant.TenantId.toLowerCase() === item?.tenantId?.toLowerCase()) &&
      userTenant.IsWrite === true
  );
  if (filterTenants.length > 0) {
    return true;
  }
  return false;
};
export const findTenant = (item) => {
  let tempTenant = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) =>
      userTenant.TenantId.toLowerCase() === item?.tenant_id?.toLowerCase() ||
      userTenant.TenantId.toLowerCase() === item?.TenantId?.toLowerCase() ||
      userTenant.TenantId.toLowerCase() === item?.tenantId?.toLowerCase()
  )[0];
  return tempTenant;
};
/**
 * check Feature Subscription
 * @param {Array} products
 * @param {String} feature_name
 * @returns {boolean}
 */

export const checkFeatureSubscription = (products, feature_name) =>
  products?.some((product) =>
    product?.features?.some(
      (feature) =>
        feature.name === feature_name && !isFeatureSubscribed(feature)
    )
  );
/**
 * isFeatureSubscribed
 * @returns {boolean}
 */
export const isFeatureSubscribed = (feature) => {
  // Check if Permissions object exists
  if (!feature.Permissions || typeof feature.Permissions !== "object") {
    return false;
  }

  // Check if the feature_id matches the FeatureId in Permissions
  return feature.Permissions.FeatureId === feature.feature_id;
};
/**
 * isAllProductFeaturesSubscribed
 * @returns {boolean}
 */
export const isAllProductFeaturesSubscribed = (product) => {
  // Check if product and features array exist
  if (
    !product ||
    !product.features ||
    !Array.isArray(product.features) ||
    product.features.length === 0
  ) {
    return false;
  }

  // Check each feature has valid permissions
  return product.features.every((feature) => {
    // Check if Permissions exists and is not null
    if (!feature.Permissions) {
      return false;
    }

    // Check if Permission is active
    return feature.Permissions.Active === true;
  });
};
/**
 * Check OrganizationDetail UserDetail
 * @param {object} obj
 * @returns {boolean}
 */
export const checkOrganizationUser = (obj) => {
  if (
    AUTH_PERMISSION.OrganizationUser === obj.AuthPermission &&
    !isAuthorizedTenant()
  ) {
    return false;
  }
  return true;
};
/**
 * is Company Admin Role
 * @param {Object} user
 * @returns {boolean}
 */
export const hasUserRole = (user, role) => {
  const rolesArray = user?.roles
    .split(",")
    .map((roleItem) => roleItem.split(":")[0].trim());
  return rolesArray?.includes(role);
};

/**
 * is smart is email
 * @param {Object} user
 * @returns {boolean}
 */
export const isSmartIsEmail = (obj) => {
  if (obj && typeof obj.name === "string") {
    return obj.name.toLowerCase().includes(SMART_IS_EMAIL);
  }
  return false;
};

/**
 * checkParentRoutePermission
 * @param {Object} obj
 * @param {Array} routes
 * @returns {boolean}
 */
export const checkParentRoutePermission = (obj, routes) => {
  if (obj?.parentid) {
    const matchingObject = routes.find((temp) => temp.id === obj?.parentid);
    if (
      matchingObject &&
      matchingObject?.AuthPermission &&
      AUTH_PERMISSION[matchingObject?.AuthPermission] &&
      AUTH_PERMISSION[matchingObject?.AuthPermission] ===
        ROLES[matchingObject?.AuthPermission] &&
      !hasUserRole(
        JSON.parse(sessionStorage.getItem(USER_DATA)),
        ROLES[matchingObject?.AuthPermission]
      )
    ) {
      return false;
    }
  }

  return true;
};
/**
 * getDefaultTenant
 * @returns {object}
 */
export const getDefaultTenant = () =>
  JSON.parse(localStorage.getItem(DEFAULT_TENANT));

/**
 * getAuthorizedTenants
 * @returns {object}
 */
export const getAuthorizedTenants = () =>
  JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) =>
      userTenant.IsWrite === true && userTenant.IsOrganization === true
  );

/**
 * isAuthorizedTenant
 * @returns {object}
 */
export const isAuthorizedTenant = () => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  return (
    userTenants?.some(
      (userTenant) =>
        userTenant.IsWrite === true && userTenant.IsOrganization === true
    ) || false
  );
};
/**
 * isPersonalTenant
 * @poram {tenant_id}
 */
export const isPersonalTenant = (tenant_id) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  return (
    userTenants?.some(
      (userTenant) =>
        userTenant.TenantId.toLowerCase() === tenant_id?.toLowerCase() &&
        userTenant.IsOrganization === false
    ) || false
  );
};
/**
 * findTenantById
 * @poram {tenant_id}
 */
export const findTenantById = (tenant_id) => {
  let userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));

  // Use return to ensure the filtered result is returned
  return userTenants.filter(
    (userTenant) =>
      userTenant.TenantId.toLowerCase() === tenant_id?.toLowerCase()
  )[0];
};

/**
 * getPersonalTenant
 * @returns {object}
 */
export const getPersonalTenant = () =>
  JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) => userTenant.IsOrganization === false
  )[0];

/**
 * isWorldTenant
 * @poram {tenant_id}
 */
export const isWorldTenant = (tenant_id) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  const tenant = userTenants.find(
    (org) => org.TenantId.toLowerCase() === tenant_id.toLowerCase()
  );
  return tenant ? tenant.TenantName === "DEFAULT" : false;
};

export default ValidatePermissions;
