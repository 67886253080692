import React from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";

import { useLocation } from "react-router-dom";
import { FormInput, MessageAlert, passwordValidator } from "smart-react";
import { useAuth } from "../../../Context/AuthContext";
import { login } from "../../Utils/CommonUtil";
import { Link } from "react-router-dom";
import "../../Pages/Splash.scss";

const dashboardPath = "/products";
const Login = ({ setIsLoader }) => {
  const [formState, setFormState] = React.useState({});
  const { updateUser, checkTokens } = useAuth();
  const [alertMessage, setAlertMessage] = React.useState({
    alert: false,
    message: null,
    type: "danger",
    iconName: "error",
  });
  const { state } = useLocation();

  React.useEffect(() => {
    if (checkTokens()) {
      window.location.replace(dashboardPath);
    }
    // Check if the user is registered and if the message has already been shown
    const isMessageShown = sessionStorage.getItem("isRegisteredMessageShown");
    if (state?.isRegistered && isMessageShown == "false") {
      // Set the alert message
      setAlertMessage({
        alert: true,
        type: "success",
        message:
          "UserDetail registered successfully. Please check your email to complete the registration process.",
        isIcon: false,
        iconName: "check",
      });

      // Set a flag in sessionStorage to prevent showing the message again on reload
      sessionStorage.setItem("isRegisteredMessageShown", true);
    }
  }, []);

  const handleSubmit = async (dataItem) => {
    setIsLoader(true);
    dataItem = {
      grant_type: "password",
      AppId: process.env.APP_ID,
      device_id: navigator.userAgent,
      browser: navigator.userAgent,
      ...dataItem,
      os: navigator.platform,
    };
    try {
      let response = await login({ dataItem, updateUser, page: "login" });
      if (response && response?.error && response?.page === "login") {
        setAlertMessage({
          alert: true,
          message: "Invalid username or password",
          type: "danger",
          iconName: "error",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoader(false);
    }
  };
  return (
    <>
      {alertMessage?.alert && (
        <MessageAlert
          type={alertMessage?.type}
          message={alertMessage?.message}
          isIcon={true}
          iconName={alertMessage?.iconName}
        />
      )}

      <Form
        initialValues={formState}
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <div>
              <Field
                key={"username"}
                id={"username"}
                name={"username"}
                label={"Username"}
                labelClass={"k-font-bold"}
                inputClass={"k-login-input"}
                type={"text"}
                component={FormInput}
                optional={false}
              />
              <Field
                key={"password"}
                id={"password"}
                name={"password"}
                label={"Password"}
                labelClass={"k-font-bold"}
                inputClass={"k-login-input"}
                type={"password"}
                component={FormInput}
                validator={passwordValidator}
                optional={false}
              />
            </div>
            <div className="k-pt-2">
              <p className="signup-link !k-m-0">
                {" "}
                <Link to="/forgot-password">Forgot Password</Link>
              </p>
            </div>
            <div
              style={{
                justifyContent: "space-between",
                alignContent: "center",
              }}
              className={
                "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
              }
            >
              <Button
                className="k-w-100"
                themeColor={"dark"}
                type={"submit"}
                rounded={"medium"}
                size={"medium"}
              >
                Login
              </Button>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};
export default Login;
