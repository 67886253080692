import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback, TextInput, InputSwitch } from "smart-react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import {
  buildNotification,
  GenerateNotification,
  isValidate,
  isFromValid,
  DescriptionTextArea,
} from "smart-react";
import {
  listRunSet,
  createRunSet,
  updateRunSet,
} from "../Services/RunSetService";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Field } from "@progress/kendo-react-form";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../../constants/eventDataTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../Core/Context/AuthContext";
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import DesktopLayout from "../Components/Layout/DesktopLayout";
import TenantDropdown from "../../../Core/Components/TenantDropdown/TenantDropdown";
import {
  ValidatePermissions,
  findTenant,
  findTenantById,
} from "../../../../Utils/Auth/ValidatePermissions";
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
} from "../../../../constants/notificationMessages";
/**
 * Add Run Set main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add Run Set component.
 */
const AddRunSet = () => {
  const navigate = useNavigate();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  const [tenant, setTenant] = React.useState(findTenantById(tenantID));
  const [isTextArea, setIsTextArea] = React.useState(false);
  const [editorField, setEditorField] = React.useState({
    name: "",
    value: "",
    label: "",
  });
  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    tenant_id: tenantID,
    TenantName: tenant?.TenantName,
    uc_ossi_run_id: "",
    uc_env_id: "%",
    uc_ossi_client_id: "",
    uc_ossi_grp: "",
    uc_ossi_sleep_sec: "",
    uc_ossi_parallel_flg: "",
    uc_ossi_disable_flg: false,
    uc_ossi_descr: "",
  });
  const requiredFields = ["uc_ossi_run_id", "uc_ossi_descr", "TenantName"];
  const [selected, setSelected] = React.useState(0);
  const [validateForm, setValidateForm] = React.useState(false);
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);

  const [editorData, setEditorData] = React.useState({
    name: "",
    value: "",
  });
  const { state } = useLocation();
  const [testState, setTestState] = React.useState(state);
  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  /**
   * Handle Selected Tab
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSelected(e.selected);
    setShouldUpdate(!shouldUpdate);
  };
  React.useEffect(() => {
    if (state) {
      const { accessToken } = getTokensFromStorage();

      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await listRunSet({
          accessToken: accessToken,
          limit: "50",
          offset: 1,
          filter: [
            {
              ColumnName: "uc_ossi_run_id",
              Op: "eq",
              ColumnValue: dataSet?.uc_ossi_run_id,
            },
            {
              ColumnName: "tenant_id",
              Op: "eq",
              ColumnValue: dataSet?.tenant_id,
            },
            {
              ColumnName: "uc_ossi_prod_id",
              Op: "eq",
              ColumnValue: dataSet?.uc_ossi_prod_id,
            },
          ],
          moduleName: "RunSets",
          isServiceWorker: false,
        });
        const runSetData = response.runSet[0];
        setTestState({ ...state, dataSet: runSetData });
        setFormData(runSetData);
        setLoading(false);
      })();
      setFormData({ ...dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
      setShouldUpdate(!shouldUpdate);
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = findTenantById(tenantID);
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        tenant_id: tenantID,
        TenantName: tempTenant?.TenantName,
      }));
    }
  }, [tenantID]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * close the editor
   * @name string
   * @data string
   */
  const closeEditor = ({ editorOBj, isClose }) => {
    if (isClose) {
      setEditorData({
        name: "",
        value: "",
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [editorOBj?.name]: editorOBj?.value,
      }));
    }
  };

  /**
   * onSave
   * submit the remote server data to api
   */
  const onSave = async ({ isExit = false, isContinue = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      uc_ossi_run_id: state?.edit
        ? formData.uc_ossi_run_id
        : tenant?.OrganizationPrefix + formData.uc_ossi_run_id,
      uc_ossi_disable_flg: formData.uc_ossi_disable_flg ? 1 : 0,
    };
    let response;
    let method;
    if (state?.edit) {
      method = updateRunSet;
      notificationMetaData = UPDATE_MESSAGE;
    } else {
      method = createRunSet;
      notificationMetaData = CREATE_MESSAGE;
      setFormData({
        tenant_id: tenantID,
        TenantName: tenant?.TenantName,
        uc_ossi_run_id: "",
        uc_env_id: "%",
        uc_ossi_client_id: "",
        uc_ossi_grp: "",
        uc_ossi_sleep_sec: "",
        uc_ossi_parallel_flg: "",
        uc_ossi_disable_flg: false,
        uc_ossi_descr: "",
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: "runset",
        isServiceWorker: false,
      });

      if (!response.IsSuccess) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.errorMessage,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
        if (isContinue) {
          navigate(`/autest/run-set`, {
            state: {
              edit: true,
              dataSet: response?.Payload,
            },
          });
        }
        if (isExit) {
          navigate("/autest/run-sets");
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to run-sets listing screen
   */
  const onExit = () => {
    navigate("/autest/run-sets");
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <>
            <div className={`${isTextArea ? "" : "k-d-none"}`}>
              <DescriptionTextArea
                onChange={handleInputChange}
                label={"Description"}
                renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                validateForm={validateForm}
                formData={formData}
                setFormData={setFormData}
                detailsDataModified={detailsDataModified}
                isTextArea={isTextArea}
                setIsTextArea={setIsTextArea}
                editorField={editorField}
                setEditorField={setEditorField}
                row={2}
              />
            </div>
            <div
              className={`add-test main-page-section add-page-section ${!isTextArea ? "" : "k-d-none"}`}
            >
              <Card className="tophead">
                <CardHeader>
                  <div className="action-buttons-container">
                    <Link
                      className="action-icon-wrapper !k-d-block"
                      to={`/autest/run-sets`}
                    >
                      <FontAwesomeIcon
                        className="k-mr-2 k-pt-3"
                        icon={faArrowLeft}
                      />
                    </Link>
                    <div>
                      <p className="display-text k-mb-0">
                        Run Set:{" "}
                        {state?.edit
                          ? formData?.uc_ossi_run_id
                          : (tenant?.OrganizationPrefix ?? "") +
                            formData?.uc_ossi_run_id}
                      </p>
                      {formData?.uc_ossi_descr && (
                        <span className="k-d-block k-mb-2">
                          {formData?.uc_ossi_descr}
                        </span>
                      )}
                    </div>
                  </div>
                </CardHeader>
              </Card>
              <Card className="k-mt-3 detail-card">
                <CardHeader>
                  <h6 className="card-title-secondary">Details</h6>
                </CardHeader>
                <CardBody className="detail-card-body">
                  <div>
                    <Form
                      onSubmit={onSave}
                      render={(formRenderProps) => (
                        <div className="card-content">
                          <div className="k-d-flex single-field-row">
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.TenantName) ? "field-invalid" : "field-valid"}`}
                            >
                              <TenantDropdown
                                textField="TenantName"
                                label="Tenant"
                                dataItemKey="TenantId"
                                className="tenant-dropdown k-align-self-end"
                                tenant={tenant}
                                setTenant={setTenant}
                                defaultItem={{
                                  TenantName: "Select Tenant ...",
                                  disabled: true,
                                }}
                                editMode={state?.edit ? "ReadOnly" : ""}
                                filterable={true}
                                tenantFormId="tenant_id"
                                tenantFormName="TenantName"
                                setFormData={setFormData}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.TenantName
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className="k-d-flex single-field-row">
                            <div
                              className={`field-wrapper ${!state?.edit && "prefix-field-wrapper"} ${isValidate(validateForm, formData.uc_ossi_run_id) ? "field-invalid" : "field-valid"}`}
                            >
                              <Field
                                key={"uc_ossi_run_id"}
                                id={"uc_ossi_run_id"}
                                name={"uc_ossi_run_id"}
                                label={"Run Id"}
                                data={formData?.uc_ossi_run_id}
                                onChange={handleInputChange}
                                type={"text"}
                                component={TextInput}
                                renderTypes={state?.edit ? "ReadOnly" : ""}
                                optional={false}
                                requiredField={true}
                                prefix={!state?.edit ? true : false}
                                prefixLabel={tenant?.OrganizationPrefix}
                              />
                              {isValidate(
                                validateForm,
                                formData.uc_ossi_run_id
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className="k-d-flex single-field-row">
                            <div
                              className={`field-wrapper field-wrapper-description field-wrapper-100 ${isValidate(validateForm, formData?.uc_ossi_descr) ? "field-invalid" : "field-valid"}`}
                            >
                              <DescriptionTextArea
                                onChange={handleInputChange}
                                label={"Description"}
                                renderTypes={
                                  !detailsDataModified ? "ReadOnly" : ""
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isTextArea={isTextArea}
                                setIsTextArea={setIsTextArea}
                                editorField={{
                                  name: "uc_ossi_descr",
                                  value: formData?.uc_ossi_descr,
                                  label: "Description",
                                }}
                                setEditorField={setEditorField}
                                row={2}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_ossi_descr
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className="k-d-flex single-field-row">
                            <div className={`field-wrapper`}>
                              <Field
                                key={"uc_ossi_grp"}
                                id={"uc_ossi_grp"}
                                name={"uc_ossi_grp"}
                                data={formData?.uc_ossi_grp}
                                onChange={handleInputChange}
                                label={"Group"}
                                type={"text"}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? "ReadOnly" : ""
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>

                          <div className="k-d-flex single-field-row k-mt-4">
                            <div className="field-wrapper">
                              <Field
                                name="uc_ossi_disable_flg"
                                component={InputSwitch}
                                label="Disabled"
                                onLabel="Yes"
                                offLabel="No"
                                id="uc_ossi_disable_flg"
                                checked={formData?.uc_ossi_disable_flg}
                                onChange={handleInputChange}
                                disabled={!detailsDataModified}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              {detailsDataModified && (
                <Card className="k-mt-3">
                  <CardHeader>
                    <div className="k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0">
                      <>
                        <Button
                          icon={"cancel"}
                          onClick={() => onExit()}
                          type="button"
                        >
                          Cancel
                        </Button>
                        <SplitButton
                          text="save"
                          themeColor={"primary"}
                          onButtonClick={() =>
                            onSave({ isExit: false, isContinue: true })
                          }
                          onItemClick={handleSaveSplit}
                        >
                          <SplitButtonItem text="Save & Close" />
                        </SplitButton>
                      </>
                    </div>
                  </CardHeader>
                </Card>
              )}
              {testState?.edit && (
                <Card className="k-mt-3 detail-grid-section">
                  <CardBody className="!k-px-0">
                    <DesktopLayout
                      handleSelect={handleSelect}
                      selected={selected}
                      detailsDataModified={detailsDataModified}
                      state={testState}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AddRunSet;
